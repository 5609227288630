.mobileNav {
	position: absolute;
	/*top: 140px;*/
	top: 0px;
	z-index: 20 !important;
	width: 100%;
	max-height: calc(100vh - 140px);
	overflow-x: hidden;
	overflow-y: auto;
	padding: 10px 0 $defaultMargin 0;
	background: $c-white;
	background: linear-gradient(180deg, rgba(244,244,244,1) 0%, rgba(202,202,202,1) 100%);
	@include boxShadow;
	transform: translate(0, -1000px);
	opacity: 0.5;
	transition: transform .7s, opacity .7s;
}

.close {
	display: block;
	text-align: right;
	@include titleFont;
	font-weight: 900;
	@include fontsize($defaultFontSize * 1.8);
	text-decoration: none;
}

.mobileNav_item {

	margin: 0 0 $navMargin 0;
	@include titleFont;
	@include fontsize($defaultFontSize * 1.3);
	line-height: 1.6;
	text-align: center;

	& a {
		text-decoration: none;
	}

	&:last-child {
		margin-bottom: 0;
	}

}

.mobileNav.drawer--open {
	transform: translate(0, 0);
	opacity: 1;
}

.mobileNav.drawer--open .mobileNav_item {

	transform: translate(0, 0);
	opacity: 1;

	&:nth-child(1) {
		transition-delay: 0.05s;
	}
	&:nth-child(2) {
		transition-delay: 0.1s;
	}
	&:nth-child(3) {
		transition-delay: 0.15s;
	}
	&:nth-child(4) {
		transition-delay: 0.2s;
	}
	&:nth-child(5) {
		transition-delay: 0.25s;
	}
	&:nth-child(6) {
		transition-delay: 0.3s;
	}

}

