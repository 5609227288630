.contactForm,
.commentForm {
	background-color: $c-lighterGray;

	h2 {
		text-align: center;
	}

	.row {
		margin:2em 0;
	}

	input,
	textarea{
		border-radius: 12px;
		line-height:1.5;
		padding:1.5rem 1.8rem;
		color:$c-medGray;
		width:100%;
		@include altBodyFont;
	}
}

.commentList {
	margin-top: $defaultMargin;
	border-top:4px solid $c-lightGray;

	.comment_header {
		margin-bottom: $defaultMargin/2;
		padding-top: $defaultMargin;
	}

	.comment_content {

		padding: $defaultMargin;
		background-color: $c-lighterGray;
		border-radius: $defaultMargin;

	}

	.comment_content.__pending {
		background-color: transparent;
		border: 1px dotted $c-darken;
	}

	.comment_meta_status {
		font-size:.8em;
		margin-left:50px;
		margin-top:10px;
	}
}

.contactForm {
	background-color: white;

	input,
	textarea {
		border:1px solid $c-medGray2;
	}
}