li {
	margin-left: $wideMargin;
}

ol {

	list-style: decimal;

	ol & {
		list-style: lower-alpha;
	}

}

ul {
	list-style: disc;
}

.list__naked {
	@include nakedList;
}


@mixin moreLink {
	@include t_altTitle;
	@include t_smallish;
	font-weight: $fw-bold;
	&:after {
		content: ' \2192';
	}
}

.moreLink {
	@include moreLink;
}