.featureArticleTile {
  display: block;
  position: relative;
  border-radius:$bigBorderRadius;

  // Sizes

  &.featureArticleTile__square {
    /*padding-bottom: 90%;*/
    /* height:500px;*/
  }

  &.featureArticleTile__wide {
    padding-bottom: calc(9 / 20.8 * 100%);
  }

  .imageBackgroundLoader {
    border-radius:$bigBorderRadius;
  }

  .featureArticleTile_bgOverlay {
    background-color: rgba(0, 0, 0, .7);
  }

  &.box_color__blog {
    &:hover {
      .featureArticleTile_bgOverlay,
      .listingTile_bgOverlay {
        background-color:$c-redHover;
      }
    }
  }

  &.box_color__webinars {
    &:hover {
      .featureArticleTile_bgOverlay,
      .listingTile_bgOverlay {
        background-color:$c-blueHover;
      }
    }
  }

  &.box_color__resources {
    &:hover {
      .featureArticleTile_bgOverlay,
      .listingTile_bgOverlay {
        background-color:$c-yellowHover;
      }
    }
  }

  &.box_color__podcast {
    &:hover {
      .featureArticleTile_bgOverlay,
      .listingTile_bgOverlay {
        background-color:$c-orangeHover;
      }
    }
  }
}

.featureArticleTile_bgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100.3141596%; // (Because subpixel rounding glitches leave a gap at the bottom sometimes when Mercury is in retrograde.)
  width: 100%;
  border-radius:$bigBorderRadius;
  pointer-events: none;
}

.featureArticleTile_label {
  border-radius:$bigBorderRadius;
  color: $c-white;
  height:480px;
  display: flex;
  justify-content:center;
  align-content:center;
  flex-direction:column;
  text-align:center;
  overflow:hidden;
  z-index:3;


  h1,
  h2 {
    z-index:3;
    color: $c-white;
    margin-bottom:0;
    padding:0 5%;
  }

  span,
  time {
    color:$c-white;
    z-index: 3;
    margin:2% auto;
  }

  .featuredArticleAuthor {
    svg {
      fill:$c-white;
      width:20px;
      top: -3px;
      position: relative;
      margin-right:5px;
    }
  }

}


.featuredArticle_Play {
  position: absolute;
  top: 0;
  left: 0;
  height: 100.3141596%; // (Because subpixel rounding glitches leave a gap at the bottom sometimes when Mercury is in retrograde.)
  width: 100%;
  text-align: center;
  cursor: pointer;

  p {
    margin-top: 16%;
    z-index: 10;
  }

  .start-video {
    height: 150px;
    display: block;
    margin-top: 16%;
    z-index: 10;

    @include mqSmallerThan('tabletLandscape') {
      margin-top: 5%;
    }
    @include mqMax('600px') {
      margin-top: 4%;

      svg {
        height:120px;
      }
    }
  }

  svg {
    fill:$c-white;
    z-index:10;
  }

}

.webinarHeader {
  iframe {
    position: relative;
    z-index:2;
    width:100%;
    height:682px;
  }
}

.item {
  .imageBackgroundLoader, .featureArticleTile, .featureArticleTile_bgOverlay, .featureArticleTile_label {
    border-radius: 0;
  }
}