#homepage-hero {
  height: 68vh;
  min-height:600px;
  position: relative;
  /*overflow:hidden;*/

  #hero-bkgrd-image {
    position: absolute;
    right:0;
    bottom:0;
    max-width:900px;
    min-width:50%;
    overflow: visible;


    @include mqMax(1200px) {
      min-width:60%;
    }

    @include mqMin(1600px) {
      img {
        max-height: 800px;
        max-width:800px;
        left:160px;
      }
    }
  }

  @include mqMax(1600px) {
    #hero-bkgrd-image img {
      height: 100% !important;
    }
  }

  @include mqMax(780px) {
    min-height:100px;
    height:58vh;

    #hero-bkgrd-image img {
      display: none;
    }

  }


  .homepage-welcome {
    position: relative;
    padding-top:10%;
    height:100%;
    z-index: 10;

    h1 {
      line-height: 1;
    }

    h2 {
      @include altTitleFont;
      @include scalingFs($defaultFontSize*.7, $defaultFontSize*1.2);
    }

    a {
      color:$c-white !important;
    }

  }

}


.homepage-cards {
  background: $c-lightGray;
  padding-bottom: $defaultMargin;

  @include mqMax(464px) {
    .search-input-box.fence__wide {
      width:100%;
    }
  }

  .col-sm-4,
  .col-sm-8 {
    padding-right:2rem;
    padding-left:2rem;
  }

  .featureArticleTile_label {

    p {
      opacity: 0;
      color:white;
    }
  }

  .featureArticleTile {
    max-height:500px;
    height:500px;

    &:hover {
      p {opacity: 1}
    }

    &.featureArticleTile__square {

    }
  }


}

.homepage-featured {
  padding: $defaultPadding 0;
  background-color: $c-lightGray;
}