@mixin buttonColors($bg, $fg) {
	color: $fg;
	background-color: $bg;
	&:hover {
		background-color: darken($bg, 10%);
		box-shadow: transparentize($bg, .77) 0 0 $defaultMargin/4;
	}
}

button, .button {
	cursor: pointer;
}

.button {

	display: inline-block;
	background-color: $c-red;
	color: $c-white !important;
	font-weight: $fw-bold;
	line-height: 1;
	text-decoration: none;


	padding: $navMargin*1.2 $defaultMargin*.9;
	@include buttonFont;
	@include t_small;
	text-transform: uppercase;
	letter-spacing: 3px;
	border-radius: $bigBorderRadius;

	&:hover {
		/*background-color: transparentize($c-red, 0.85);*/
		opacity:.6;
	}

	.svg-arrow-right-dims {
		width:16px;
		height:16px;
		display: inline;
		margin-top:-2px;
		margin-left:8px;
		fill:$c-white;
	}

}

.button__submit {
	padding: $defaultMargin*.5 $defaultMargin;
}

.button__small {
	@include fontsize($smallFontSize);
}

.button__blue {
	background-color: $c-blue;
}

.button__darkBlue {
	background-color: $c-medBlue;
}

.button__green {
	background-color: $c-green;
}


.button__podcast-badge {
	margin: $defaultMargin/2;
	height: 40px;

	& > img {
		height: 100%;
	}
}

.button__podcast-icon {
	display: inline-block;
	height: 40px;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	& > img,
	& > svg {
		display: inline-block;
		height: 100%;
		margin-right: $defaultMargin/2;
	}
}

.inline-link {
	display:block;
	position: relative;

	img {
		display:inline;
	}
}
