input {
	color:$c-medGray;
	@include altBodyFont;
}

[type=submit] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.input-shadow {
	padding:7em 20% 4em;
	margin:0 auto;

	@include mqMax(1100px)
	{
		padding-left:0;
		padding-right:0;
	}

	label {
		position: relative;
		border-radius:$bigBorderRadius;
		background:$c-white;
		height:30px;
		padding:20px;
		box-shadow: 0px 0px 50px 30px rgba(154,154,157,0.25);
	}

	input[type="text"],
	input[type="email"] {
		width:90%;
		padding:10px 10px 10px 34px;
		background: transparent;
	}
}

.search-container {

	label:before {
		content: "";
		position: absolute;
		left: 20px;
		top: 2px;
		bottom: 0;
		width: 20px;
		background: url("/assets/icons/magnify-glass-right.png") center / contain no-repeat;
	}
}


ul.errors {
	display: none;
}
.has-error {
	.form-control,
	.custom-file-label {
		border-color: $c-red !important;
	}

	.form-check-label {
		color: $c-red
	}

	ul.errors {
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: inherit;
	}

	ul.errors > li {
		font-size: 80%;
		color: $c-red;
		margin-top: .25rem;
	}
}

.alert {
	padding:$defaultMargin;
}
.alert-success {
	background-color: $c-yellowHover;
}
.alert-danger {
	background-color: $c-red;
	color:white;
}

.form-horizontal .form-group .col-sm-3 select {
	min-width:130px;
}

.blockForm {
	border-radius: 30px;
	position: relative;
	color:$c-white;
	margin-left:1em;
	margin-right:1em;

	.blockFormPanel {
		position: relative;

		h3, p {
			color:$c-white;
		}
		.blockFormCta {
			font-size: 2rem;
			line-height: 1.3;
			margin:0;
		}
	}
	.blockPadding {
		padding: 3.5rem 2rem 3.5rem 3.5rem;
	}

	.hs-form {
		margin-top:60px;

		div.input {
			margin:0;
			width:80%;
		}
		.hs-submit {
			left: 70.59%;
		}
		@include mqSmallerThan('desktop') {
			div.input {
				width:100%;
			}
			.hs-submit {
				left:auto;
				top:40px;
				width:auto;
				margin:0px auto 40px;
				text-align:center;
			}
		}
	}
}

.borderRadiusLeft {
	border-top-left-radius: 30px !important;
	border-bottom-left-radius: 30px !important;
}

/*.form__articleInline {

	margin: 0 0 $wideMargin;
	padding: $wideMargin;
	background: mix($c-grey, $c-white, 7%);
	border-top: 7px solid $c-plum;

	.formHeading {
		margin-top: 0;
		font-weight: $fw-bold;
	}

	.formField {

		width: 100%;

		> label {
			display: block;
			margin: $navMargin 0;
			@include altTitleFont;
			@include fontsize($smallFontSize);
			font-weight: $fw-bold;
		}

		& div.input {
			width: 100%;
		}

		& input, textarea {
			display: block;
			width: 100%;
			padding: $navMargin/2 $navMargin;
			background: $c-lighten;
			border: 1px dotted $c-darken;
		}

	}

	.formField__hidden {
		display: none !important;
	}

	.formSubmit {

		input[type=submit] {
			display: inline-block;
			margin-top: $navMargin;
			padding: $navMargin $defaultMargin;
			@include buttonColors($c-plum, $c-white);
			@include altTitleFont;
			@include t_smallish;
			font-weight: $fw-bold;
			text-transform: uppercase;
			line-height: 1;
			cursor: pointer;

		}

	}

}*/
