// ------ PANELS

@mixin panelContainer () {

  display: table;
  width: 100%;

  @include mqSmallerThan(tabletPortrait) {
    display: block;
  }

}

.panelContainer, %panelContainer {
  @include panelContainer;
}


@mixin panel {
  margin: 0;
  padding: $defaultPadding;
  display: table-cell;
  height: 100%;
  vertical-align: top;
  @include centerBackground;
}

.panel, %panel {
  @include panel;
}


@mixin panel--oneThird () {

  @include panel;

  width: 33.333%;

  @include mqSmallerThan(desktop) {
    width: 50%;
  }
  @include mqSmallerThan(tabletPortrait) {
    width: 100%;
    display: block;
  }

}

@mixin panel--twoThirds {

  @include panel;

  width: 66.666%;

  @include mqSmallerThan(desktop) {
    width: 50%;
  }
  @include mqSmallerThan(tabletPortrait) {
    width: 100%;
    display: block;
  }

}

@mixin panel--half {

  @extend %panel;

  width: 50%;

  @include mqSmallerThan(tabletPortrait) {
    width: 100%;
    display: block;
  }

}


.panel--oneThird {
  @include panel--oneThird;
}
.panel--twoThirds {
  @include panel--twoThirds;
}

.panel--half {
  @include panel--half;
}

.panel--logo {
  padding: 5%;
  text-align: center;
  vertical-align: middle;
}

.panel--image {
  min-height: 200px;
  @include mqSmallerThan(tabletPortrait) {
    padding-top: 40%;
  }
  position: relative;
}

.panel--extraPadding {
  padding: $defaultPadding*2 $defaultPadding*1.5;
}
