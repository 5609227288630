.__mTop {
	margin-top: $wideMargin;
}

.__mTop__wee {
	margin-top: $defaultMargin;
}

.__mTop__wide {
	margin-top: $wideMargin*2;
}
.__mTop__wide__3 {
	margin-top: $wideMargin*3;
}

.__mBottom {
	margin-bottom: $wideMargin;
}

.__mBottom__wide {
	margin-bottom: $wideMargin*2;
}

.__mBottom__wide__3 {
	margin-bottom: $wideMargin*3;
}
.__mBottom_wee {
	margin-bottom: 1rem;
}

.__mV {
	margin-top: $wideMargin;
	margin-bottom: $wideMargin;
}

.__mV__wee {
	margin-top: $defaultMargin;
	margin-bottom: $defaultMargin;
}

.__pTop {
	padding-top: $wideMargin;
}
.__pTop__wide {
	padding-top: $wideMargin*2;
}

.__pBottom {
	padding-bottom: $wideMargin;
}

.__pBottom__wide {
	  padding-bottom: $wideMargin*2;
}
.__pBottom__wide__3 {
	padding-bottom: $wideMargin*3;
}

.float__right {
	 float:right;
}
.float__left {
	float:left;
}

.item {
	.articleContent.col-xs-12 {
		padding:0 !important;
	}
}

.display_inline {
	display: inline;
}