/*
 * Colors
 */
$c-red: #eb4234;
$c-yellow: #7fee3b;
$c-orange: #fbb856;
$c-blue: #7ed5e7;
$c-green: #faaf4c;

$c-redHover: rgba(255, 66, 52, 0.85);
$c-yellowHover: rgba(127, 238, 59, 0.85);
$c-orangeHover: rgba(251, 184, 86, 0.85);
$c-blueHover: rgba(126, 213, 231, 0.85);

$c-lightGray: #f4f4f4;
$c-medGray: #9a9a9d;
$c-darkGray: #404042;

$c-white: #ffffff;
$c-black: #000000;

$c-lightRed: #ef4c3b;
$c-blueGray: #bed0d3;
$c-lightBlue: #67c4d2;
$c-lightBlue2: #52abb8;
$c-lightBlue3: #73cfe4;
$c-medBlue: #1f4d5b;
$c-darkBlue: #132e39;

$c-lighterGray: #efefef;
$c-medGray2: #bdbdbd;

$c-footerGrayLight: #dfdfdf;
$c-footerGrayMed: #b5b5b5;
$c-footerGrayDark: #808080;

$c-background: white;
$c-text: black;
$c-reverseText: white;

$c-lighten: rgba(255, 255, 255, 0.2);
$c-darken: rgba(0, 0, 0, 0.2);

$colors: (

	red: $c-red,
	yellow: $c-yellow,
	orange: $c-orange,
	blue: $c-blue,
	green: $c-green,
	blueGray: $c-blueGray,
	lightBlue: $c-lightBlue,
	lightBlue2: $c-lightBlue2,
	lightBlue3: $c-lightBlue3,
	medBlue: $c-medBlue,
	darkBlue: $c-darkBlue,
	lighterGray: $c-lighterGray,
	lightGray: $c-lightGray,
	medGray: $c-medGray,
	white: $c-white,
	black: $c-black

);

/*
 * ReFrame colors
 */
$c-thisIsToday: #2c5664;
$c-thinkChristian: #b24525;
$c-familyFire: #24488b;
$c-groundwork: #84ba64;
$c-churchJuice: #f68d21;
$c-kidsCorner: #8e4e9e;

/*
 * Share colors
 */
$c-facebookBlue: #537bbd;
$c-twitterBlue: #78cdf0;
$c-rssOrange: #f39200;
$c-emailRed: #028628;
$c-pinterestRed: #bd081c;

/*
 * Color utility classes
 */
@each $name, $color in $colors {
	.c-#{$name} {
		color: $color;
	}
	.c-#{$name}-light {
		color: lighten($color, 20%);
	}
	.c-#{$name}-lighter {
		color: lighten($color, 40%);
	}
	.c-#{$name}-dark {
		color: darken($color, 20%);
	}
	.c-#{$name}-darker {
		color: darken($color, 40%);
	}
	.bg-#{$name} {
		background-color: $color;
	}
	.bg-#{$name}-light {
		background-color: lighten($color, 20%);
	}
	.bg-#{$name}-lighter {
		background-color: lighten($color, 40%);
	}
	.bg-#{$name}-dark {
		background-color: darken($color, 20%);
	}
	.bg-#{$name}-darker {
		background-color: darken($color, 40%);
	}
}


/*
 * Opacity
 */
$tintOpacity: 0.2;
$overlayOpacity: 0.5;
$smokeOpacity: 0.7;



/*
 * Typography
 */
$defaultFontSize: 20;
$smallFontSize: 14;

// Heading font sizes
$fs-hero: 48;
$fs-pageHeading: 40;
$fs-contentHeading: 36;
$fs-sectionHeading: 30;
$fs-cardHeading: 24;
$fs-sidebarHeading: 20;
$fs-textHeading: $defaultFontSize;
$fs-meta: $smallFontSize;
$fs-wee: $smallFontSize*0.9;

// Component font sizes
$fs-icon: 24;
$fs-toolbarIcon: 24;

// Font-weights
$fw-bold: 900;
$fw-normal: 400;
$fw-light: 300;



/*
 * Spacing
 */
$navMargin: rems($smallFontSize);
$weePadding: $navMargin/3;
$smallerMargin: 2rem;
$defaultMargin: 3rem;
$largerMargin: 4rem;
$wideMargin: rems($defaultFontSize*2);
$defaultPadding: rems($defaultFontSize);


/*
 * Timing
 */
$transitionTime: .5s;
$fastTransitionTime: .2s;


/*
 * Dimensions
 */
$borderRadius: 1px;
$medBorderRadius: 20px;
$bigBorderRadius: 30px;

$tightFenceWidth: 768px;
$fenceWidth: 960px;
$wideFenceWidth: 1680px;
$standardWidth: 1280px;
$bodyWidth: 1920px;


// c.f. https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
$breakpoints: (
	standard: (min: 0px, max: 999999px),
	fence: (min: 0px, max: $fenceWidth),
	phone: (min: 0px, max: 600px),
	tabletPortrait: (min: 601px, max: 900px), // "sm" grid
	tabletLandscape: (min: 901px, max: 1200px), // "md" grid
	desktop: (min: 1201px, max: 1800px), // "desktop" grid
	bigass: (min: 1801px, max: 999999px), // "lg" grid
);

/*
 * Flexbox Grid
 */
$grid-columns: 12;

$gutter-width: rems($defaultFontSize);
$gutter-width-half: $gutter-width * .5;
$gutter-width-double: $gutter-width * 2;

$outer-margin: $gutter-width-double * 2;

$gridBreakpoints: (
	sm: map-get(map-get($breakpoints, 'tabletPortrait'), 'min'),
	md: map-get(map-get($breakpoints, 'tabletLandscape'), 'min'),
	desktop: map-get(map-get($breakpoints, 'desktop'), 'min'),
	lg: map-get(map-get($breakpoints, 'bigass'), 'min'),
);

$flexboxgrid-max-width: rems($bodyWidth);