.pageContainer {
	max-width: $bodyWidth;
	width: 100%;
	margin:0 auto;
}

.pageContent {
	margin: 0 auto;
	padding-top:150px;
	background-color: $c-white;

	a {
		text-decoration: none;
		color:$c-lightBlue2;

		&:hover {
			opacity: 0.7;

			img {
				opacity: 0.8;
			}
		}
	}

	.box_color__blog {
		a {
			background-color:$c-redHover;
			width:100%;
			height:100%;

			&:hover {
				opacity: 1;
				background-color:$c-redHover;
				z-index:3;
			}
		}
	}
	.box_color__webinars {
		a {
			&:hover {
				opacity: 1;
				background-color:$c-blueHover;
			}
		}
	}
	.box_color__podcast {
		a {
			&:hover {
				opacity: 1;
				background-color:$c-orangeHover;
				z-index:3;
			}
		}
	}
	.box_color__resource,
	.box_color__resources {
		a {
			&:hover {
				opacity: 1;
				background-color:$c-yellowHover;
			}
		}
	}

}

.bg-darkNavy {
	background-color: $c-medBlue;
}
