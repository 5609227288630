/*
.library_controlsList {
  list-style:none;

  li {
    margin-left: 0;

    .library_facet {
      span.title {
        text-transform: capitalize;
      }
    }
  }

}*/
.searchbar {

  position: relative;

  & > .searchbar_input {

    display: block;
    width: 100%;
    padding: $navMargin $navMargin $navMargin $navMargin*4;
    border: 3px solid $c-lightGray;
    @include fontsize($fs-textHeading);
    border-radius: $borderRadius;

    @include mqIs(phone)
    {
      padding-left: $navMargin;
      @include fontsize($defaultFontSize);
    }

  }

  & > .searchbar_icon {

    pointer-events: none;
    position: absolute;
    top: 23px;
    left: 23px;
    height: 100%;
    width: $navMargin*2;
    color: mix($c-lightBlue2, $c-white);

    & > svg {
      height: 100%;
      width: $navMargin*2;
    }

    @include mqIs(phone)
    {
      display: none;
    }

  }

}


.library_results {
  & mark {
    background-color: transparent;
    background-image: linear-gradient(-180deg, transparent, transparentize($c-yellow, .3) 35%, transparent);
  }

  .articleImageFrame {
    padding-bottom: 0;
    margin-bottom: 1em;
  }

  h4 {
    @include fontsize(22);
    padding:0 !important;
    margin:0 !important;
  }

  .articlePreviewCard_meta {
    @include fontsize(16);
  }

}


.library_searchbarContainer {
  margin-bottom: $defaultMargin;
}


.library_controls {
  background-color: $c-lightGray;
  margin-right: $defaultMargin;
  padding: $navMargin $defaultMargin $defaultMargin;
  border-radius: $borderRadius;
}


.library_controlsHeading {
  margin: 0 0 $navMargin;
  padding: 0 0 4px;
  @include altTitleFont;
  @include t_smallish;
  font-weight: $fw-bold;
  text-transform: uppercase;
  border-bottom: 1px dotted $c-darken;

}

.library_controlsList {

  margin: 0 0 $defaultMargin;
  list-style: none;

  line-height: 1.2;
  @include altTitleFont;
  @include fontsize($fs-meta);

  & li {

    margin: $navMargin*.7 0;

    &.selected > .library_facet {
      font-weight: $fw-bold;
      color: darken($c-green, 10%);
    }

  }

  li ul {
    margin: 0 0 0 $navMargin;
  }

  input[type=checkbox] {
    display: none;
  }

  & + button {
    display: block;
    margin-top: - $defaultMargin*.5;
    margin-bottom: $defaultMargin;
  }
}

.library_facet {

  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;

  & > .count {
    display: inline-block;
    justify-self: end;
    margin-left: auto;
    padding-left: $defaultPadding*.25;
  }

  span.title {
    text-transform: capitalize;
  }

  &:hover > .title {
    text-decoration: underline;
  }

}

.library_searchResultSnippet {

  margin: $navMargin 0;
  padding: 0 0 $navMargin;
  border-bottom: 3px solid #eee;
  @include fontsize(16);
  font-style: italic;

  $a: 0.8;

  &.__default {
    border-color: rgba($c-medGray, $a);
  }

  &.__blog {
    border-color: rgba($c-red, $a);
  }

  &.__webinar {
    border-color: rgba($c-blue, $a);
  }

  &.__resource {
    border-color: rgba($c-yellow, $a);
  }

  &.__podcast {
    border-color: rgba($c-orange, $a);
  }

}


.library_pagebar {
  background-color: mix($c-black, $c-white, 3%);
  padding: 1rem 2rem;
  border-radius: $borderRadius;
  @include altTitleFont;
  @include fontsize($fs-meta);
  line-height:1;
}

.library_pagebar_pageLink {

  padding: .5rem;

  @include mqSmallerThan('tabletPortrait') {
    padding: $defaultMargin/3;
  }

  text-decoration: none;

  &.selected {
    text-decoration: underline;
    font-weight: $fw-bold;
  }
  @at-root button#{&}:hover {
    text-decoration: underline;
  }
  @at-root span#{&} {
    cursor: default;
  }
}

.library_alert {
  background-color: $c-orange;
  padding: $defaultMargin;
  @include fontsize($fs-meta);
  border-radius: $borderRadius;
}

.library_info {
  background-color: $c-lightGray;
  padding: 1rem;
  @include fontsize($fs-meta);
  border-radius: $borderRadius;

  button {
    display: inline-block;
    padding: .625rem;
    background-color: rgba(0,0,0,.05);
    color: currentColor;
    font-weight: 900;
    line-height: 1;
    text-decoration: none;
    border-radius:1px;
    letter-spacing:0;
    text-transform:capitalize;
  }
}

.library_error {
  background-color: $c-lightRed;
  padding: $defaultMargin;
  @include fontsize($fs-meta);
  border-radius: $borderRadius;
  color:white;
}
