.siteHeader {

  position: fixed;
  display: block;
  z-index: 20;
  width: 100%;
  height:150px;
  padding: $navMargin 0;
  background-color: $c-white;
  /*@include boxShadow;*/

  /*
   * Animations
   */
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: transform $transitionTime, opacity $transitionTime;

  &.toolbar__out {
    transform: translate3d(0, -150px, 0);
    opacity: 1;
  }

}

.page .siteHeader,
.listing .siteHeader {
  background-color:$c-lighterGray;
}

.home .siteHeader {
  background-color: $c-white;
}


.mainNav {
  display: flex;
  justify-content: flex-start; // Shouldn't matter, because .mainNav_links will flex-grow
  align-items: center;
  align-content: center;
}

.mainNav_links {

  flex-grow: 1;

  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  align-content: center;

  @include nakedList;

  & > li {
    margin: 0 0 0 $defaultMargin;
  }

  #mainNavItem__search {
    margin-left:2em;
  }

}

.mainNav_link {

  text-decoration: none;

  @include titleFont;
  @include t_smallish;
  font-weight: $fw-bold;
  text-transform: uppercase;

  padding-bottom: 10px;

  &.__active:not(.__withIcon) {
    border-bottom: 3px solid currentColor;
  }

  &:hover {
    border-bottom:3px solid;
  }

  &.color__blog { border-color:$c-red !important; }
  &.color__webinars { border-color:$c-blue !important; }
  &.color__podcast { border-color:$c-orange !important; }
  &.color__resources { border-color:$c-yellow !important; }

  &.__withIcon {
    border-color:$c-green !important;

    svg {
      width:1.6em;
      height:1.6em;
    }
  }

}

.siteHeader_logo {
  line-height: 1;
}

.siteHeader_logoLink {

  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin: 0 $defaultMargin 0 0;
  @include scaling('max-width', 200, 400);

  @include mqMax('767px') {
    margin:0;
  }

  svg {
    width: 100%;
    margin: 0;
  }

}
/*
 * Responsive nav treatment
 */


#mainNavItem__menu {
  min-width: 18px;
  vertical-align: 5%;
  text-decoration:none;
  border:none;
  position: absolute;
  right:2.5%;

  svg {
    width:34px;
    stroke: $c-darkGray;
  }

  a {
    border: none;
    
    &:hover {
      text-decoration: none;
      border: none;
    }
  }
}

@include mqMin(1070px)
{
  #mainNavItem__menu {
    display: none;
  }
}

@include mqMax(1070px)
{
  .mainNav_links {
    display: none;
    width:0;
    margin:0;
    padding:0;
    height:0;
  }
}
