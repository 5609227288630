.podcastHeader {
	background-color:$c-orange;
	color:$c-white;
}
.resourceHeader {
	background-color:$c-yellow;
	color:$c-white;
}
.webinarHeader {
	 background-color: $c-blue;
	 color:$c-white;
}
.grayHeader {
	background-color: $c-lightGray;
}
.whiteHeader {
	background-color: $c-white;
}

.articleRow {
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	position: relative;
	height:100%;
}

@media only screen and (max-width: 48em) {
	.articleRow {
		box-sizing: border-box;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 0;
		-webkit-flex: 0 1 auto;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-right: -0.5rem;
		margin-left: -0.5rem;
	}
}

.authorPage,
.authorMeta {
	.authorImage {
		width:100%;
		height:100%;
		max-height: 220px;
		max-width:220px;
		margin:0 auto;

		.imageLoader {
			height:100%;
			border-radius: 50%;

			img {
				border-radius: 50%;
				height:100%;
			}
		}
	}

	.authorSocials {
		@include nakedList;
		margin-bottom:40px;
		flex-grow: 1;

		display: flex;
		justify-content: center;
		align-items: baseline;
		align-content: center;

		li {
			margin: 0 5px 0 5px;

			svg {
				fill:$c-lightBlue2;
				width:30px;
				height:30px;
			}
		}

		li.socialAuthor,
		li.socialWebsite {
			svg {
				width:26px;
				height:26px;
			}
		}
	}

	h4 {
		margin-top:20px;
		margin-bottom:10px;
		@include t_h4__sm;
	}
}

.articleMeta {
	position: -webkit-sticky;
	position: sticky;
	top: 200px;
	align-self: flex-start;
	margin:0 auto;
	text-align:center;


	ol {
		text-align:left;
		margin-left:5%;
		list-style: none;

		@include mqLargerThan(tabletLandscape) {
			margin-left:14%;
		}

		li + li {
			margin-top:5px;
		}

		li {
			list-style:none;
			margin-left:0;
			@include buttonFont;
			font-size: 20px;
			color:$c-medGray;
			line-height:1.5;

			a {
				font-size: 20px;
				@include buttonFont;
				line-height: 1;
				color:$c-medGray;

				&:hover,
				&.active {
					color:$c-lightBlue3;
				}
			}

		}
	}
}

@media only screen and (max-width: 48em) {

	.articleMeta {
		position: relative;
		margin:20px 0;
		padding-top:20px;
		top:0;
		text-align:left;
		border-top:1px solid $c-lightBlue;

		.authorImage {
			height:200px;
			width:200px;
			float:left;
			margin-right:20px;
		}

		.authorSocials {
			justify-content: start;
		}

	}
		
}



.articleImageFrame {
	padding: 4% 0 5%;
	/*padding-bottom: calc(0.7% + 4px);*/
	position: relative;
	margin: 0 0 $wideMargin*1.5;
}

.articleAudioPlayer {
	margin-top: $defaultMargin;
	margin-bottom: $defaultMargin;
}

.article_teaser {
	display: block;
	position: relative;
	margin: $wideMargin*1.5 0 $wideMargin*1.2;
	text-align: center;
}

.article_teaser:after {

	display: block;
	margin: $defaultMargin*3 auto $defaultMargin*3;
	width: 7%;
	height: 10px;
	content: ' ';
	/*background: $c-blueGray; // default, overridden by page theme*/
	/*@include __bg-themeDust;*/
	border-radius: $borderRadius;

	@include mqSmallerThan('tabletLandscape') {
		margin: $defaultMargin*2 auto $defaultMargin*2;
		width: 14%;
	}

}

.article_pullQuote {

	padding: $wideMargin;
	text-align: center;

	& blockquote {
		// Reset styles from default content blockquote...
		margin: 0 !important;
		padding: 0 !important;
		border: none !important;
	}

	& .article_pullQuote_quote {
		@include t_h3;
	}

	& .article_pullQuote_attribution {
		margin-top: $defaultMargin;
	}

}

.articleAuthors {
	margin: $wideMargin 0 $wideMargin*2;
	padding: $wideMargin 0 0;
	border-top: 3px solid $c-blueGray;
	@include t_smallish;
}

.articleAuthors_profile {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
}

.articleAuthors_profileImage {

	width: 142px;
	flex-basis: 142px;
	flex-grow: 1;
	margin-bottom: $defaultMargin;

	@include mqAtLeast('tabletPortrait') {
		margin-right: $wideMargin;
	}

}

.articleAuthors_content {
	flex-basis: 442px;
	flex-grow: 11;
}

.articleAuthors_profileImage, .articleAuthors_content {
	text-align: center;
	@include mqMin('694px') {
		text-align: left;
	}
}

.articleAuthors_moreLink {
	@include moreLink;
}

/*
 * Headings & Typography
 */

.article_body {

	h2 {
		@include t-h3;
		margin: $wideMargin*1.5 0 $wideMargin;
	}

	h3 {
		@include t-h4;
		margin: $wideMargin 0 $defaultMargin;
	}

	h4 {
		@include t_h4__alt__sm;
		margin: $defaultMargin 0;
	}

	p:first-of-type {
		@include fontsize(26);
	}
}



.articleContent_video {
	
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	margin: $defaultMargin auto;
	width: 100%;
	border: $navMargin solid $c-blueGray;

	& > .articleContent_video_player {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

}

.articleContent_embed {
	position: relative;
	margin: $defaultMargin auto;
	width: 100%;
	border: $navMargin solid $c-blueGray;
}

.article_topics {
	@include fontsize($smallFontSize);
	font-weight: $fw-bold;
}

.articleActions_item {

	margin: 0 $wideMargin $defaultMargin;
	@include t_altTitle;
	@include t_smallish;
	font-weight: $fw-bold;
	text-transform: uppercase;


	&.share {
		color: $c-facebookBlue;
	}
	&.tweet {
		color: $c-twitterBlue;
	}

	& a {
		text-decoration: none;
	}

	& .articleActions_item_label {
		margin-left: $defaultMargin/2;
	}

	& a:hover .articleActions_item_label {
		text-decoration: underline;
	}

}


/*
 * Article theme
 */

.article_body h3, .article_body h4, .article_body a, .article_body a:link, .article_pullQuote {


}

.article_body blockquote {
	margin-left: $defaultMargin;
	padding: 0 $defaultMargin;
	border-left: 7px solid $c-darken;
	/*@include __border-themeDust;*/
}


.progress-bar {
	height:0px;
}

.article .progress-bar {
	background: url('../icons/progress-bar.png') 100%;
	background-repeat: no-repeat;
	width: var(--scroll);
	position: absolute;
	top: 150px;
	left: 0;
	height: 6px;
	z-index: 1;
	max-width:1920px;
}

.authorPage {

	.authorHeader {
		position: relative;
		margin:0 auto 2rem;

		.authorImage {
			margin: 0 auto 1rem;
			max-height:360px;
			max-width:360px;
		}

		h1 {
			margin: 0 auto;
		}
	}
}