.listingTiles {

  .imageLoader,
  img {
    border-radius: $bigBorderRadius;

  }

  .subscribe-cta {
    img {
      border-radius: 0;
    }
  }

  .featuredArticle {
    .listingTile {
      margin-top: 0 !important;
    }
  }

  .col-xs-12 {
    padding-left: 1em;
    padding-right: 1em;
  }

  h3 {
    @include t_h4;
    margin: 1% 0;
  }

  .postMeta {
    @include t_smallish;
    margin-bottom: 20px;

    svg {
      width: 20px;
      height: 20px;
      fill: $c-darkGray;
      margin-bottom: 8px;
      margin-right: 8px;
    }

    .metaComments {
      margin-left: 28px;
    }

  }


  .listingTile {
    border-radius:$bigBorderRadius;
    max-height:500px;
    height:500px;
    display: block;
    position: relative;
    overflow:hidden;

    &.box_color__blog {
      &:hover {
        .listingTile_bgOverlay {
          background-color:$c-redHover;
          opacity:1;
        }
      }
    }
    &.box_color__webinar {
      &:hover {
        .listingTile_bgOverlay {
          background-color:$c-blueHover;
          opacity:1;
        }
      }
    }
    &.box_color__resource {
      &:hover {
        .listingTile_bgOverlay {
          background-color:$c-yellowHover;
          opacity:1;
        }
      }
    }
    &.box_color__podcast {
      &:hover {
        .listingTile_bgOverlay {
          background-color:$c-orangeHover;
          opacity:1;
        }
      }
    }

    .imageBackgroundLoader {
      border-radius:$bigBorderRadius;
    }

    .listingTile_bgOverlay {
      background-color: rgba(0, 0, 0, .5);
      position: absolute;
      top: 0;
      left: 0;
      height: 100.3141596%; // (Because subpixel rounding glitches leave a gap at the bottom sometimes when Mercury is in retrograde.)
      width: 100%;
      border-radius:$bigBorderRadius;
      pointer-events: none;
    }

    .listingTile_label {
      border-radius:$bigBorderRadius;
      color: $c-white;
      height:100%;
      display: flex;
      flex-direction:column;
      overflow:hidden;
      z-index:3;


      .listingBottom {
        position: absolute;
        bottom:4rem;
        transition: bottom 350ms;
        transition-timing-function: ease-in-out;
      }

      .listingTeaser {
        position: absolute;
        margin:2rem;
        top:30rem;
        transition: top 350ms;
        transition-timing-function: ease-in-out;
      }

      h2 {
        @include t_h4;
        z-index:3;
        color: $c-white;
        margin:1rem 2rem 0;
      }

      h5 {
        z-index: 3;
        color: $c-white;
        @include t_h5_alt;
        text-transform: uppercase;
        letter-spacing: 0.5em;
        margin:4rem 2rem 6rem;

        @include mqMax(1500px) {
          @include scalingFs($defaultFontSize*.9, $defaultFontSize*.9);
        }
        @include mqMax(600px) {
          @include scalingFs($defaultFontSize*.7, $defaultFontSize*.7);
        }
      }

      .postMeta {
        margin:0 2rem;
        z-index: 10;

        span {
          z-index: 10;
          color: $c-white;
          @include t_smallish;

          svg {
            fill: $c-white;
            z-index: 10;
          }
        }


        .metaIcon_space {
          margin-left: 30px;
        }

        .metaIcon_play-circle {
          svg {
            width: 48px;
            height: 48px;
            margin-top:8px;
          }
        }

        .metaIcon_downloads {
          svg {
            width:32px;
            height:32px;
            stroke:$c-white;
            fill:transparent;
          }
        }
      }

    }

  }

  .hoverTile {

    a {
      &:hover {
        opacity: 1 !important;

        img {
          opacity: 1 !important;
        }

        .listingBottom {
          bottom:22rem;
        }

        .listingTeaser {
          top:8rem;
        }

      }
    }

  }

  @include mqSmallerThan('desktop') {

    .listingTile {
      .listingTile_label {
        .listingBottom,
        .listingTeaser {
          top: auto;
        }

        .listingBottom {
          margin-top:20px;
        }

      }
    }

    .hoverTile {

      .listingTile_label {
        .listingBottom,
        .listingTeaser {
          position: relative;
          bottom: 15px;
        }
      }

      a {
        &:hover {
          .listingBottom,
          .listingTeaser {
            bottom: 15px;
            top:auto;
            opacity:.8;
          }
        }
      }
    }

  }

  @include mqMinMax(768px, 1201px) {

    .listingTeaser,
    .listingTeaser p {
      @include t_listing;
    }

  }

  @include mqMax(767px) {

    .listingTeaser,
    .listingTeaser p {
      font-size:.85em;
    }
  }

  @include mqMax(480px) {

    .listingTile {
      .listingTile_label {
        .listingTeaser {
          margin-top:10px;

          p {
            @include t_listing_phone;
          }
        }
      }
    }

  }

}




.load-more-spinner {
  display:none;
}

.load-more-btn {
  @include t_h3;
  z-index: 12;
  color:$c-blue;
  cursor: pointer;
}


/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-fall,
.la-ball-fall > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.la-ball-fall {
  display: block;
  font-size: 0;
  color: #fff;
}
.la-ball-fall.la-dark {
  color: #333;
}
.la-ball-fall > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.la-ball-fall {
  width: 54px;
  height: 18px;
}
.la-ball-fall > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: ball-fall 1s ease-in-out infinite;
  -moz-animation: ball-fall 1s ease-in-out infinite;
  -o-animation: ball-fall 1s ease-in-out infinite;
  animation: ball-fall 1s ease-in-out infinite;
}
.la-ball-fall > div:nth-child(1) {
  -webkit-animation-delay: -200ms;
  -moz-animation-delay: -200ms;
  -o-animation-delay: -200ms;
  animation-delay: -200ms;
}
.la-ball-fall > div:nth-child(2) {
  -webkit-animation-delay: -100ms;
  -moz-animation-delay: -100ms;
  -o-animation-delay: -100ms;
  animation-delay: -100ms;
}
.la-ball-fall > div:nth-child(3) {
  -webkit-animation-delay: 0ms;
  -moz-animation-delay: 0ms;
  -o-animation-delay: 0ms;
  animation-delay: 0ms;
}
.la-ball-fall.la-sm {
  width: 26px;
  height: 8px;
}
.la-ball-fall.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px;
}
.la-ball-fall.la-2x {
  width: 108px;
  height: 36px;
}
.la-ball-fall.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px;
}
.la-ball-fall.la-3x {
  width: 162px;
  height: 54px;
}
.la-ball-fall.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px;
}
/*
 * Animation
 */
@-webkit-keyframes ball-fall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-145%);
    transform: translateY(-145%);
  }
  10% {
    opacity: .5;
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  80% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  90% {
    opacity: .5;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(145%);
    transform: translateY(145%);
  }
}
@-moz-keyframes ball-fall {
  0% {
    opacity: 0;
    -moz-transform: translateY(-145%);
    transform: translateY(-145%);
  }
  10% {
    opacity: .5;
  }
  20% {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  80% {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  90% {
    opacity: .5;
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(145%);
    transform: translateY(145%);
  }
}
@-o-keyframes ball-fall {
  0% {
    opacity: 0;
    -o-transform: translateY(-145%);
    transform: translateY(-145%);
  }
  10% {
    opacity: .5;
  }
  20% {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  80% {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  90% {
    opacity: .5;
  }
  100% {
    opacity: 0;
    -o-transform: translateY(145%);
    transform: translateY(145%);
  }
}
@keyframes ball-fall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-145%);
    -moz-transform: translateY(-145%);
    -o-transform: translateY(-145%);
    transform: translateY(-145%);
  }
  10% {
    opacity: .5;
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  80% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  90% {
    opacity: .5;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(145%);
    -moz-transform: translateY(145%);
    -o-transform: translateY(145%);
    transform: translateY(145%);
  }
}
