.masthead {
	text-align: center;

	h1 {
		padding:0;
		margin:0;
	}
}

.page,
.listing {
	.masthead {
		background-color: $c-lighterGray;
	}
}

.mastheadImage {
	position: relative;
	margin: 0 0 $wideMargin*1.5;
	/*max-height:650px;*/
}

.readMore {
	@include t_h4__sm;
	color:$c-lightBlue3;
}


/*
 * Page loading animation
 */

.page__loading {
	transform: scale(0.8);
	opacity: 0.1;
}

.page__loaded {
	transform: scale(1);
	opacity: 1;
	background-color: transparent;
}

.cat-link {
	color:black !important;
}
.cat-box {
	position: relative;
	text-align:center;
	background-color: $c-lightGray;
	padding:40px 0 60px;
	@include t_h5;
	border-radius:20px;
	margin: 0 .25em 30px;

	.cat-icon {
		display: block;
		fill:none;
		stroke:$c-black;
		max-width:120px;
		margin:0 auto;
	}

	.churchjuice-icon {
		max-width:80px;
		margin:0 auto;
		display: block;
	}

	&:hover {
		background-color: $c-footerGrayLight;
	}
}

.searchResults {
	h4 {
		margin-bottom:0;
	}
	p { margin-top:0px; }
	.meta {
		@include t_smallish;
	}
}