.siteFooter {

	margin: 0;
	padding: 5rem 0 2rem;
	background-color: $c-footerGrayLight;
	color: $c-darkGray;

	@include altTitleFont;

	@include mqMax('767px') {
		text-align: center;
	}

	h4 {
		margin:0 0 10px 0;
	}
}

.icon.siteFooter_cjLogo {
	width: 10em;
	height: 6em;
}

.siteFooter_logoLink {

	display: inline-block;
	@include scaling('max-width', 200, 400);
	width: 10em;
	height: 6em;

	svg {
		width: 100%;
	}

}

.siteFooter_socialLinks {

	margin: $defaultMargin 0 $defaultMargin*1.5;
	text-align: center;

	.siteFooter_socialIcon {
		width:22px;
		height:22px;
		fill:$c-footerGrayLight;
	}
	& .iconCircle {
		background-color:$c-white;
		margin-right: 1.2em;
	}
	& .iconCircle:hover {
		background-color: $c-footerGrayDark;
	}

}

.siteFooter_linkList {

	@include altBodyFont;
	line-height: 1.2;

	& li {
		margin: 0 0 5px;
		list-style: none;
	}

	& a {
		text-decoration: none;
	}

	& a:hover {
		text-decoration: underline;
	}

}

.siteFooter_reframeInfo {

	@include altBodyFont;
	text-align: left;

	@include mqMax('767px') {
		text-align: center;
		border-top: 1px dotted $c-darkGray;
		margin-top: $wideMargin;
		padding-top: $wideMargin;

	}

	.siteFooter_reframeInfo--logo {
		svg {
			width:125px;
			height:40px;
		}
	}

}

.icon.siteFooter_reframeLogo {
	width: 123px;
	height: 42px;

}


.programsFooter {
	background-color: $c-footerGrayMed;
	color:$c-white;
	padding: $wideMargin 0;
}

.programsFooter_programLinks_intro {
	@include altTitleFont;
	@include fontsize($smallFontSize);
	color: rgba($c-white, $overlayOpacity);
	margin-bottom: $navMargin;
	text-align: center;
}

.programsFooter_programLinks {

	@include flexbox();
	@include flex(0, 0, 1);
	@include flex-direction(row);
	@include flex-wrap(wrap);

	justify-content: center;
	text-align: center;
	align-items: center;

	@include mqLargerThan(tabletLandscape) {
		justify-content: space-around;
	}

}

.programsFooter_programLinks_link {

	display: inline-block;
	flex: 1;
	min-width: 125px;
	margin: $navMargin $defaultMargin;


	.siteFooter_programIcon {

		height: 30px;
		width: 60px;
		fill:rgba($c-white, $overlayOpacity);

		&.__today {
			width: 124px;
			height: 42px;
			top: 4px;
		}

		&.__groundwork {
			width: 144px;
			height: 52px;
			top: -7px;
		}

		&.__familyfire {
			width: 114px;
			height: 54px;
		}

		&.__kidscorner {
			width: 92px;
			height: 54px;
		}

		&.__thinkchristian {
			width: 177px;
			height: 47px;
			top: 4px;
		}

		&:hover {
			fill:$c-white;
		}

	}

}


.attributionFooter {

	background-color: $c-footerGrayDark;
	color:$c-white;
	padding: $defaultPadding 0;

	&, & * {
		transition: color $fastTransitionTime linear 0;
	}

	@include altBodyFont;
	@include fontsize($smallFontSize);

	& a:hover {
	}

	& p {
		margin-top: .1em;
	}

	@include mqMax(480px) {
		p {
			text-align:center;
		}
	}

}