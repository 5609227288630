.subscribe-cta {
  background-color: $c-white;
  overflow:hidden;
  max-height:800px;

  .fence__wide {
    position: relative;
    min-height:1400px;
  }

  .subscribe-cta-circle {
    position: absolute;
    height: 900px;

    &.cta-orange-circle {
      left:-333px;
      top:-260px;
    }

    &.cta-red-circle {
      left:-495px;
      top:280px;
    }
  }

  .subscribe-cta-content {
    z-index: 10;
    text-align:center;
    position: relative;
    padding-top:180px;

    h4 {
      @include altTitleFont;
      @include scalingFs($defaultFontSize*.8, $defaultFontSize*1.2);
    }
  }

  .button__blue {
    position: absolute;
    right:-2px;
    top:0;
    padding: 27px $defaultMargin*.9;
    border-radius:40px;
  }

}