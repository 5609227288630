/*
 * Super-SASSy functions!
 */
/*
 * Colors
 */
/*
 * ReFrame colors
 */
/*
 * Share colors
 */
/*
 * Color utility classes
 */
.c-red {
  color: #eb4234; }

.c-red-light {
  color: #f49891; }

.c-red-lighter {
  color: #fdefee; }

.c-red-dark {
  color: #a81c11; }

.c-red-darker {
  color: #4c0d07; }

.bg-red {
  background-color: #eb4234; }

.bg-red-light {
  background-color: #f49891; }

.bg-red-lighter {
  background-color: #fdefee; }

.bg-red-dark {
  background-color: #a81c11; }

.bg-red-darker {
  background-color: #4c0d07; }

.c-yellow {
  color: #7fee3b; }

.c-yellow-light {
  color: #bcf699; }

.c-yellow-lighter {
  color: #fafef7; }

.c-yellow-dark {
  color: #4eb310; }

.c-yellow-darker {
  color: #255607; }

.bg-yellow {
  background-color: #7fee3b; }

.bg-yellow-light {
  background-color: #bcf699; }

.bg-yellow-lighter {
  background-color: #fafef7; }

.bg-yellow-dark {
  background-color: #4eb310; }

.bg-yellow-darker {
  background-color: #255607; }

.c-orange {
  color: #fbb856; }

.c-orange-light {
  color: #fde2ba; }

.c-orange-lighter {
  color: white; }

.c-orange-dark {
  color: #e68b05; }

.c-orange-darker {
  color: #824e03; }

.bg-orange {
  background-color: #fbb856; }

.bg-orange-light {
  background-color: #fde2ba; }

.bg-orange-lighter {
  background-color: white; }

.bg-orange-dark {
  background-color: #e68b05; }

.bg-orange-darker {
  background-color: #824e03; }

.c-blue {
  color: #7ed5e7; }

.c-blue-light {
  color: #d4f1f7; }

.c-blue-lighter {
  color: white; }

.c-blue-dark {
  color: #28b9d7; }

.c-blue-darker {
  color: #186f81; }

.bg-blue {
  background-color: #7ed5e7; }

.bg-blue-light {
  background-color: #d4f1f7; }

.bg-blue-lighter {
  background-color: white; }

.bg-blue-dark {
  background-color: #28b9d7; }

.bg-blue-darker {
  background-color: #186f81; }

.c-green {
  color: #faaf4c; }

.c-green-light {
  color: #fddbaf; }

.c-green-lighter {
  color: white; }

.c-green-dark {
  color: #da7f06; }

.c-green-darker {
  color: #774503; }

.bg-green {
  background-color: #faaf4c; }

.bg-green-light {
  background-color: #fddbaf; }

.bg-green-lighter {
  background-color: white; }

.bg-green-dark {
  background-color: #da7f06; }

.bg-green-darker {
  background-color: #774503; }

.c-blueGray {
  color: #bed0d3; }

.c-blueGray-light {
  color: #fbfcfc; }

.c-blueGray-lighter {
  color: white; }

.c-blueGray-dark {
  color: #81a4aa; }

.c-blueGray-darker {
  color: #507075; }

.bg-blueGray {
  background-color: #bed0d3; }

.bg-blueGray-light {
  background-color: #fbfcfc; }

.bg-blueGray-lighter {
  background-color: white; }

.bg-blueGray-dark {
  background-color: #81a4aa; }

.bg-blueGray-darker {
  background-color: #507075; }

.c-lightBlue {
  color: #67c4d2; }

.c-lightBlue-light {
  color: #b6e3e9; }

.c-lightBlue-lighter {
  color: white; }

.c-lightBlue-dark {
  color: #3094a3; }

.c-lightBlue-darker {
  color: #194c54; }

.bg-lightBlue {
  background-color: #67c4d2; }

.bg-lightBlue-light {
  background-color: #b6e3e9; }

.bg-lightBlue-lighter {
  background-color: white; }

.bg-lightBlue-dark {
  background-color: #3094a3; }

.bg-lightBlue-darker {
  background-color: #194c54; }

.c-lightBlue2 {
  color: #52abb8; }

.c-lightBlue2-light {
  color: #9aced6; }

.c-lightBlue2-lighter {
  color: #e3f1f3; }

.c-lightBlue2-dark {
  color: #306c74; }

.c-lightBlue2-darker {
  color: #12292c; }

.bg-lightBlue2 {
  background-color: #52abb8; }

.bg-lightBlue2-light {
  background-color: #9aced6; }

.bg-lightBlue2-lighter {
  background-color: #e3f1f3; }

.bg-lightBlue2-dark {
  background-color: #306c74; }

.bg-lightBlue2-darker {
  background-color: #12292c; }

.c-lightBlue3 {
  color: #73cfe4; }

.c-lightBlue3-light {
  color: #c9ecf4; }

.c-lightBlue3-lighter {
  color: white; }

.c-lightBlue3-dark {
  color: #27acca; }

.c-lightBlue3-darker {
  color: #166375; }

.bg-lightBlue3 {
  background-color: #73cfe4; }

.bg-lightBlue3-light {
  background-color: #c9ecf4; }

.bg-lightBlue3-lighter {
  background-color: white; }

.bg-lightBlue3-dark {
  background-color: #27acca; }

.bg-lightBlue3-darker {
  background-color: #166375; }

.c-medBlue {
  color: #1f4d5b; }

.c-medBlue-light {
  color: #398da7; }

.c-medBlue-lighter {
  color: #76bbd0; }

.c-medBlue-dark {
  color: #050d0f; }

.c-medBlue-darker {
  color: black; }

.bg-medBlue {
  background-color: #1f4d5b; }

.bg-medBlue-light {
  background-color: #398da7; }

.bg-medBlue-lighter {
  background-color: #76bbd0; }

.bg-medBlue-dark {
  background-color: #050d0f; }

.bg-medBlue-darker {
  background-color: black; }

.c-darkBlue {
  color: #132e39; }

.c-darkBlue-light {
  color: #2d6c86; }

.c-darkBlue-lighter {
  color: #53a4c6; }

.c-darkBlue-dark {
  color: black; }

.c-darkBlue-darker {
  color: black; }

.bg-darkBlue {
  background-color: #132e39; }

.bg-darkBlue-light {
  background-color: #2d6c86; }

.bg-darkBlue-lighter {
  background-color: #53a4c6; }

.bg-darkBlue-dark {
  background-color: black; }

.bg-darkBlue-darker {
  background-color: black; }

.c-lighterGray {
  color: #efefef; }

.c-lighterGray-light {
  color: white; }

.c-lighterGray-lighter {
  color: white; }

.c-lighterGray-dark {
  color: #bcbcbc; }

.c-lighterGray-darker {
  color: #898989; }

.bg-lighterGray {
  background-color: #efefef; }

.bg-lighterGray-light {
  background-color: white; }

.bg-lighterGray-lighter {
  background-color: white; }

.bg-lighterGray-dark {
  background-color: #bcbcbc; }

.bg-lighterGray-darker {
  background-color: #898989; }

.c-lightGray {
  color: #f4f4f4; }

.c-lightGray-light {
  color: white; }

.c-lightGray-lighter {
  color: white; }

.c-lightGray-dark {
  color: #c1c1c1; }

.c-lightGray-darker {
  color: #8e8e8e; }

.bg-lightGray {
  background-color: #f4f4f4; }

.bg-lightGray-light {
  background-color: white; }

.bg-lightGray-lighter {
  background-color: white; }

.bg-lightGray-dark {
  background-color: #c1c1c1; }

.bg-lightGray-darker {
  background-color: #8e8e8e; }

.c-medGray {
  color: #9a9a9d; }

.c-medGray-light {
  color: #cececf; }

.c-medGray-lighter {
  color: white; }

.c-medGray-dark {
  color: #67676a; }

.c-medGray-darker {
  color: #353536; }

.bg-medGray {
  background-color: #9a9a9d; }

.bg-medGray-light {
  background-color: #cececf; }

.bg-medGray-lighter {
  background-color: white; }

.bg-medGray-dark {
  background-color: #67676a; }

.bg-medGray-darker {
  background-color: #353536; }

.c-white {
  color: #ffffff; }

.c-white-light {
  color: white; }

.c-white-lighter {
  color: white; }

.c-white-dark {
  color: #cccccc; }

.c-white-darker {
  color: #999999; }

.bg-white {
  background-color: #ffffff; }

.bg-white-light {
  background-color: white; }

.bg-white-lighter {
  background-color: white; }

.bg-white-dark {
  background-color: #cccccc; }

.bg-white-darker {
  background-color: #999999; }

.c-black {
  color: #000000; }

.c-black-light {
  color: #333333; }

.c-black-lighter {
  color: #666666; }

.c-black-dark {
  color: black; }

.c-black-darker {
  color: black; }

.bg-black {
  background-color: #000000; }

.bg-black-light {
  background-color: #333333; }

.bg-black-lighter {
  background-color: #666666; }

.bg-black-dark {
  background-color: black; }

.bg-black-darker {
  background-color: black; }

/*
 * Opacity
 */
/*
 * Typography
 */
/*
 * Spacing
 */
/*
 * Timing
 */
/*
 * Dimensions
 */
/*
 * Flexbox Grid
 */
/*
 * Super-SASSy mixins
 */
/*
 * Value/unit breakpoints
 */
/*
 * Named breakpoints
 */
/**
Scales any property.
Usage example: `@include scaling('height', 1, 120);`
@copyright: Aaron Waldon <aaron@causingeffect.com>, January 2017
 */
/**
Scaling font-size.
Usage example: `@include scalingFs(100, 600);`
@copyright: Aaron Waldon <aaron@causingeffect.com>, January 2017
 */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
* {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*
 * Base styles
 */
html {
  /*overflow-y: scroll;*/ }
  html.html--noScroll {
    overflow: hidden; }

body {
  background-color: #f4f4f4; }
  .html--noScroll body {
    overflow-y: hidden; }

::-moz-selection {
  color: white;
  background: black;
  text-shadow: none; }

::selection {
  color: white;
  background: black;
  text-shadow: none; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

/*
 * Starter styles
 */
*,
*:after,
*:before {
  box-sizing: border-box; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

body {
  position: relative;
  margin: 0 auto;
  overflow-x: hidden;
  display: block; }

p, ul, ol, blockquote {
  position: relative; }
  p + p, ul + p, ol + p, blockquote + p, p + ul, ul + ul, ol + ul, blockquote + ul, p + ol, ul + ol, ol + ol, blockquote + ol, p + blockquote, ul + blockquote, ol + blockquote, blockquote + blockquote {
    margin-top: 1.5rem; }

li {
  position: relative; }
  li + li {
    margin-top: 1.5rem; }

sub, sup {
  /* Specified in % so that the sup/sup is the
       right size relative to the surrounding text */
  font-size: 75%;
  /* Zero out the line-height so that it doesn't
       interfere with the positioning that follows */
  line-height: 0;
  /* Where the magic happens: makes all browsers position
       the sup/sup properly, relative to the surrounding text */
  position: relative;
  /* Note that if you're using Eric Meyer's reset.css, this
       is already set and you can remove this rule */
  vertical-align: baseline; }

sup {
  /* Move the superscripted text up */
  top: -0.5em; }

sub {
  /* Move the subscripted text down, but only
       half as far down as the superscript moved up */
  bottom: -0.25em; }

/*
 * @debt 2018-01-25 Michael
 *
 * I don't remember exactly why I had the need to add this rule, but it ended up causing the the nested lists in the
 * Search sidebar to be rendered with wonky spacing, so I took it out. (The commit message when I added it [3a1aa39] was:
 * "Fixed a CSS corner case where the first LI in a list-within-a-list didn't receive consistent spacing.")
 * I think this was referring to a spacing issue in the nested lists on the Sitemap page, which I've now fixed by
 * correcting the markup of those lists. If it's been a while and we haven't found any other places where this is needed,
 * we should go ahead and purge this now-unused code.
 */
a {
  color: currentColor; }

i {
  font-style: normal; }

button {
  border: none;
  background: transparent; }

input[type="search"] {
  box-sizing: border-box; }

/*
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
  display: block;
  position: relative; }

.img__stretch {
  width: 100%; }

.img__circle {
  border-radius: 50%; }

/*
 * Transitions
 */
* {
  transition: all 0s linear 0s, opacity 0.5s ease-in-out 0s, background-position 0.5s ease-in-out 0s, color 0.2s linear 0s, background-color 0.2s linear 0s, font-size 0s linear 0s, line-height 0s linear 0s; }

.__limitedTransitions, .__limitedTransitions *, html {
  transition: color 0.2s ease 0s, background-color 0.2s ease 0s; }

/*
 * Helpers
 */
.clearfix {
  *transform: scale(1); }
  .clearfix:before, .clearfix:after {
    content: " ";
    display: table; }
  .clearfix:after {
    clear: both; }

.mb {
  overflow: hidden;
  _overflow: visible;
  transform: scale(1); }

.mb_body {
  margin-top: 0;
  overflow: hidden;
  _overflow: visible;
  transform: scale(1); }

.mb .mb_media {
  float: left;
  margin-right: 3rem;
  margin-top: 0; }

.mb .mb_media__alt {
  float: right;
  margin-left: 3rem;
  margin-top: 0; }

.mb .mb_media img, .mb .mb_media__alt img {
  display: block; }

.hidden {
  display: none !important;
  visibility: hidden; }

.visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute; }

.invisible {
  visibility: hidden; }

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .hideOnPhone {
    display: none !important;
    visibility: hidden; } }

.onlyOnPhone {
  display: none;
  visibility: hidden; }
  @media only screen and (min-width: 0px) and (max-width: 600px) {
    .onlyOnPhone {
      display: inherit;
      visibility: inherit; } }

.fadeContent {
  opacity: 0 !important;
  transition: opacity 350ms ease-in-out;
  height: 0px !important; }

.isVisible {
  opacity: 1 !important;
  height: auto !important; }

.pageContainer {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto; }

.pageContent {
  margin: 0 auto;
  padding-top: 150px;
  background-color: #ffffff; }
  .pageContent a {
    text-decoration: none;
    color: #52abb8; }
    .pageContent a:hover {
      opacity: 0.7; }
      .pageContent a:hover img {
        opacity: 0.8; }
  .pageContent .box_color__blog a {
    background-color: rgba(255, 66, 52, 0.85);
    width: 100%;
    height: 100%; }
    .pageContent .box_color__blog a:hover {
      opacity: 1;
      background-color: rgba(255, 66, 52, 0.85);
      z-index: 3; }
  .pageContent .box_color__webinars a:hover {
    opacity: 1;
    background-color: rgba(126, 213, 231, 0.85); }
  .pageContent .box_color__podcast a:hover {
    opacity: 1;
    background-color: rgba(251, 184, 86, 0.85);
    z-index: 3; }
  .pageContent .box_color__resource a:hover,
  .pageContent .box_color__resources a:hover {
    opacity: 1;
    background-color: rgba(127, 238, 59, 0.85); }

.bg-darkNavy {
  background-color: #1f4d5b; }

em, .em {
  font-style: italic; }

strong, .strong {
  font-weight: bold; }

.serif {
  font-family: museo-slab, serif;
  font-weight: 300;
  font-style: normal; }

.sansSerif, .imageCaption, .mediaCredit {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400; }

body {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.7;
  font-family: museo-slab;
  font-weight: 300;
  color: #404042; }

h1 {
  font-size: 34px;
  font-size: 2.125rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  line-height: 1.3;
  margin: 3rem 0; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    h1 {
      font-size: calc( 0.01806*100vw - -25.33333px); } }
  @media only screen and (min-width: 1920px) {
    h1 {
      font-size: 60px;
      font-size: 3.75rem; } }

h2 {
  font-size: 28px;
  font-size: 1.75rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  line-height: 1.4;
  margin: 4rem 0 2rem 0; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    h2 {
      font-size: calc( 0.01389*100vw - -21.33333px); } }
  @media only screen and (min-width: 1920px) {
    h2 {
      font-size: 48px;
      font-size: 3rem; } }

h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  line-height: 1.4;
  margin: 4rem 0 2rem 0; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    h3 {
      font-size: calc( 0.01389*100vw - -13.33333px); } }
  @media only screen and (min-width: 1920px) {
    h3 {
      font-size: 40px;
      font-size: 2.5rem; } }

h4 {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  line-height: 1.4;
  margin: 4rem 0 2rem 0; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    h4 {
      font-size: calc( 0.00694*100vw - -16.66667px); } }
  @media only screen and (min-width: 1920px) {
    h4 {
      font-size: 30px;
      font-size: 1.875rem; } }

h5 {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  margin: 4rem 0 2rem 0; }

.body__alt {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.7;
  font-family: museo-slab;
  font-weight: 300;
  color: #404042; }

.h3__alt {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  line-height: 1.4;
  font-family: museo-slab;
  font-weight: 500;
  font-weight: 700;
  line-height: 1.3;
  margin: 3rem 0;
  text-transform: uppercase; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .h3__alt {
      font-size: calc( 0.00694*100vw - -16.66667px); } }
  @media only screen and (min-width: 1920px) {
    .h3__alt {
      font-size: 30px;
      font-size: 1.875rem; } }

.h4__alt {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: museo-slab;
  font-weight: 500;
  font-weight: 900;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 3rem 0; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .h4__alt {
      font-size: calc( 0.00694*100vw - -16.66667px); } }
  @media only screen and (min-width: 1920px) {
    .h4__alt {
      font-size: 30px;
      font-size: 1.875rem; } }

.h4__sm {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  line-height: 1.4;
  font-weight: 900;
  font-size: 16.8px;
  font-size: 1.05rem;
  margin: 3rem 0; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .h4__sm {
      font-size: calc( 0.00694*100vw - -16.66667px); } }
  @media only screen and (min-width: 1920px) {
    .h4__sm {
      font-size: 30px;
      font-size: 1.875rem; } }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .h4__sm {
      font-size: calc( 0.00222*100vw - -15.73333px); } }
  @media only screen and (min-width: 1920px) {
    .h4__sm {
      font-size: 20px;
      font-size: 1.25rem; } }

.h4__alt__sm {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: museo-slab;
  font-weight: 500;
  font-weight: 900;
  line-height: 1.4;
  font-size: 16.8px;
  font-size: 1.05rem;
  margin: 3rem 0; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .h4__alt__sm {
      font-size: calc( 0.00694*100vw - -16.66667px); } }
  @media only screen and (min-width: 1920px) {
    .h4__alt__sm {
      font-size: 30px;
      font-size: 1.875rem; } }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .h4__alt__sm {
      font-size: calc( 0.00222*100vw - -15.73333px); } }
  @media only screen and (min-width: 1920px) {
    .h4__alt__sm {
      font-size: 20px;
      font-size: 1.25rem; } }

.h2_lg {
  font-size: 34px;
  font-size: 2.125rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  line-height: 1.3;
  text-align: center; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .h2_lg {
      font-size: calc( 0.01806*100vw - -25.33333px); } }
  @media only screen and (min-width: 1920px) {
    .h2_lg {
      font-size: 60px;
      font-size: 3.75rem; } }

.t_callout {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1.5; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .t_callout {
      font-size: calc( 0.00972*100vw - -21.33333px); } }
  @media only screen and (min-width: 1920px) {
    .t_callout {
      font-size: 40px;
      font-size: 2.5rem; } }

.t_altTitle {
  font-family: museo-slab;
  font-weight: 500;
  text-transform: uppercase; }

.t_altTitle__sm {
  font-size: 14px;
  font-size: 0.875rem;
  font-family: museo-slab;
  font-weight: 500;
  text-transform: uppercase; }

.t_smallish {
  font-size: 17px;
  font-size: 1.0625rem; }

.__flipDecoration {
  text-decoration: none; }
  .__flipDecoration:hover {
    text-decoration: underline; }

.__noDecoration {
  text-decoration: none; }

.t_left {
  text-align: left; }

.t_right {
  text-align: right; }

.t_center {
  text-align: center; }

.icon {
  position: relative;
  display: inline;
  display: inline-block;
  fill: currentColor;
  width: 1em;
  height: 1em;
  max-width: 100%; }

.iconCircle {
  display: inline-block;
  padding: .20em 0 0;
  width: 2.25em;
  height: 2.25em;
  border-radius: 50%;
  text-align: center; }

.__mTop {
  margin-top: 2.5rem; }

.__mTop__wee {
  margin-top: 3rem; }

.__mTop__wide {
  margin-top: 5rem; }

.__mTop__wide__3 {
  margin-top: 7.5rem; }

.__mBottom {
  margin-bottom: 2.5rem; }

.__mBottom__wide {
  margin-bottom: 5rem; }

.__mBottom__wide__3 {
  margin-bottom: 7.5rem; }

.__mBottom_wee {
  margin-bottom: 1rem; }

.__mV {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.__mV__wee {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.__pTop {
  padding-top: 2.5rem; }

.__pTop__wide {
  padding-top: 5rem; }

.__pBottom {
  padding-bottom: 2.5rem; }

.__pBottom__wide {
  padding-bottom: 5rem; }

.__pBottom__wide__3 {
  padding-bottom: 7.5rem; }

.float__right {
  float: right; }

.float__left {
  float: left; }

.item .articleContent.col-xs-12 {
  padding: 0 !important; }

.display_inline {
  display: inline; }

.fence {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 1280px; }

.fence__wee {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 1280px;
  max-width: 960px; }

.fence__wide {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 1280px;
  max-width: 1680px; }

.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto; }

.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem; }

.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.boxed-row {
  max-width: 980px; }

.row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.col-xs {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%; }

.col-xs-1 {
  -webkit-flex-basis: 8.333%;
  -ms-flex-preferred-size: 8.333%;
  flex-basis: 8.333%;
  max-width: 8.333%; }

.col-xs-2 {
  -webkit-flex-basis: 16.667%;
  -ms-flex-preferred-size: 16.667%;
  flex-basis: 16.667%;
  max-width: 16.667%; }

.col-xs-3 {
  -webkit-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  -webkit-flex-basis: 33.333%;
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%; }

.col-xs-5 {
  -webkit-flex-basis: 41.667%;
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%; }

.col-xs-6 {
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  -webkit-flex-basis: 58.333%;
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%; }

.col-xs-8 {
  -webkit-flex-basis: 66.667%;
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%; }

.col-xs-9 {
  -webkit-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  -webkit-flex-basis: 83.333%;
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%; }

.col-xs-11 {
  -webkit-flex-basis: 91.667%;
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%; }

.col-xs-12 {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-1 {
  margin-left: 8.333%; }

.col-xs-offset-2 {
  margin-left: 16.667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.333%; }

.col-xs-offset-5 {
  margin-left: 41.667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.333%; }

.col-xs-offset-8 {
  margin-left: 66.667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.333%; }

.col-xs-offset-11 {
  margin-left: 91.667%; }

.start-xs {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: start; }

.center-xs {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.end-xs {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end; }

.top-xs {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.middle-xs {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.bottom-xs {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end; }

.around-xs {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.between-xs {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.first-xs {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1; }

.last-xs {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1; }

@media only screen and (min-width: 30em) {
  .container {
    width: 98%; }
  .col-xm,
  .col-xm-1,
  .col-xm-2,
  .col-xm-3,
  .col-xm-4,
  .col-xm-5,
  .col-xm-6,
  .col-xm-7,
  .col-xm-8,
  .col-xm-9,
  .col-xm-10,
  .col-xm-11,
  .col-xm-12,
  .col-xm-offset-1,
  .col-xm-offset-2,
  .col-xm-offset-3,
  .col-xm-offset-4,
  .col-xm-offset-5,
  .col-xm-offset-6,
  .col-xm-offset-7,
  .col-xm-offset-8,
  .col-xm-offset-9,
  .col-xm-offset-10,
  .col-xm-offset-11,
  .col-xm-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .col-xm {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .col-xm-1 {
    -webkit-flex-basis: 8.333%;
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-xm-2 {
    -webkit-flex-basis: 16.667%;
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-xm-3 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-xm-4 {
    -webkit-flex-basis: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-xm-5 {
    -webkit-flex-basis: 41.667%;
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-xm-6 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-xm-7 {
    -webkit-flex-basis: 58.333%;
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-xm-8 {
    -webkit-flex-basis: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-xm-9 {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-xm-10 {
    -webkit-flex-basis: 83.333%;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-xm-11 {
    -webkit-flex-basis: 91.667%;
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-xm-12 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-xm-offset-1 {
    margin-left: 8.333%; }
  .col-xm-offset-2 {
    margin-left: 16.667%; }
  .col-xm-offset-3 {
    margin-left: 25%; }
  .col-xm-offset-4 {
    margin-left: 33.333%; }
  .col-xm-offset-5 {
    margin-left: 41.667%; }
  .col-xm-offset-6 {
    margin-left: 50%; }
  .col-xm-offset-7 {
    margin-left: 58.333%; }
  .col-xm-offset-8 {
    margin-left: 66.667%; }
  .col-xm-offset-9 {
    margin-left: 75%; }
  .col-xm-offset-10 {
    margin-left: 83.333%; }
  .col-xm-offset-11 {
    margin-left: 91.667%; }
  .start-xm {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .center-xm {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center; }
  .end-xm {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .top-xm {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start; }
  .middle-xm {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  .bottom-xm {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end; }
  .around-xm {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .between-xm {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .first-xm {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1; }
  .last-xm {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; } }

@media only screen and (min-width: 48em) {
  .container {
    width: 98%; }
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .col-sm {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-flex-basis: 8.333%;
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-sm-2 {
    -webkit-flex-basis: 16.667%;
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-sm-3 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-flex-basis: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-sm-5 {
    -webkit-flex-basis: 41.667%;
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-sm-6 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-flex-basis: 58.333%;
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-sm-8 {
    -webkit-flex-basis: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-sm-9 {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-flex-basis: 83.333%;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-sm-11 {
    -webkit-flex-basis: 91.667%;
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-sm-12 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-1 {
    margin-left: 8.333%; }
  .col-sm-offset-2 {
    margin-left: 16.667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.333%; }
  .col-sm-offset-5 {
    margin-left: 41.667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.333%; }
  .col-sm-offset-8 {
    margin-left: 66.667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.333%; }
  .col-sm-offset-11 {
    margin-left: 91.667%; }
  .start-sm {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .center-sm {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center; }
  .end-sm {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .top-sm {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start; }
  .middle-sm {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  .bottom-sm {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end; }
  .around-sm {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .between-sm {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .first-sm {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1; }
  .last-sm {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; } }

@media only screen and (min-width: 64em) {
  .container {
    width: 100%; }
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .col-md {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .col-md-1 {
    -webkit-flex-basis: 8.333%;
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-md-2 {
    -webkit-flex-basis: 16.667%;
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-md-3 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-flex-basis: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-md-5 {
    -webkit-flex-basis: 41.667%;
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-md-6 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-flex-basis: 58.333%;
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-md-8 {
    -webkit-flex-basis: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-md-9 {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-flex-basis: 83.333%;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-md-11 {
    -webkit-flex-basis: 91.667%;
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-md-12 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-1 {
    margin-left: 8.333%; }
  .col-md-offset-2 {
    margin-left: 16.667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.333%; }
  .col-md-offset-5 {
    margin-left: 41.667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.333%; }
  .col-md-offset-8 {
    margin-left: 66.667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.333%; }
  .col-md-offset-11 {
    margin-left: 91.667%; }
  .start-md {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .center-md {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center; }
  .end-md {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .top-md {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start; }
  .middle-md {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  .bottom-md {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end; }
  .around-md {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .between-md {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .first-md {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1; }
  .last-md {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; } }

@media only screen and (max-width: 80em) {
  .container {
    width: 98%; }
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .col-lg {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-flex-basis: 8.333%;
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-lg-2 {
    -webkit-flex-basis: 16.667%;
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-lg-3 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-flex-basis: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-lg-5 {
    -webkit-flex-basis: 41.667%;
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-lg-6 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-flex-basis: 58.333%;
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-lg-8 {
    -webkit-flex-basis: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-lg-9 {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-flex-basis: 83.333%;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-lg-11 {
    -webkit-flex-basis: 91.667%;
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-lg-12 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-1 {
    margin-left: 8.333%; }
  .col-lg-offset-2 {
    margin-left: 16.667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.333%; }
  .col-lg-offset-5 {
    margin-left: 41.667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.333%; }
  .col-lg-offset-8 {
    margin-left: 66.667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.333%; }
  .col-lg-offset-11 {
    margin-left: 91.667%; }
  .start-lg {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .center-lg {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center; }
  .end-lg {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .top-lg {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start; }
  .middle-lg {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  .bottom-lg {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end; }
  .around-lg {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .between-lg {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .first-lg {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1; }
  .last-lg {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; } }

.featureArticleTile {
  display: block;
  position: relative;
  border-radius: 30px; }
  .featureArticleTile.featureArticleTile__square {
    /*padding-bottom: 90%;*/
    /* height:500px;*/ }
  .featureArticleTile.featureArticleTile__wide {
    padding-bottom: calc(9 / 20.8 * 100%); }
  .featureArticleTile .imageBackgroundLoader {
    border-radius: 30px; }
  .featureArticleTile .featureArticleTile_bgOverlay {
    background-color: rgba(0, 0, 0, 0.7); }
  .featureArticleTile.box_color__blog:hover .featureArticleTile_bgOverlay,
  .featureArticleTile.box_color__blog:hover .listingTile_bgOverlay {
    background-color: rgba(255, 66, 52, 0.85); }
  .featureArticleTile.box_color__webinars:hover .featureArticleTile_bgOverlay,
  .featureArticleTile.box_color__webinars:hover .listingTile_bgOverlay {
    background-color: rgba(126, 213, 231, 0.85); }
  .featureArticleTile.box_color__resources:hover .featureArticleTile_bgOverlay,
  .featureArticleTile.box_color__resources:hover .listingTile_bgOverlay {
    background-color: rgba(127, 238, 59, 0.85); }
  .featureArticleTile.box_color__podcast:hover .featureArticleTile_bgOverlay,
  .featureArticleTile.box_color__podcast:hover .listingTile_bgOverlay {
    background-color: rgba(251, 184, 86, 0.85); }

.featureArticleTile_bgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100.3141596%;
  width: 100%;
  border-radius: 30px;
  pointer-events: none; }

.featureArticleTile_label {
  border-radius: 30px;
  color: #ffffff;
  height: 480px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  z-index: 3; }
  .featureArticleTile_label h1,
  .featureArticleTile_label h2 {
    z-index: 3;
    color: #ffffff;
    margin-bottom: 0;
    padding: 0 5%; }
  .featureArticleTile_label span,
  .featureArticleTile_label time {
    color: #ffffff;
    z-index: 3;
    margin: 2% auto; }
  .featureArticleTile_label .featuredArticleAuthor svg {
    fill: #ffffff;
    width: 20px;
    top: -3px;
    position: relative;
    margin-right: 5px; }

.featuredArticle_Play {
  position: absolute;
  top: 0;
  left: 0;
  height: 100.3141596%;
  width: 100%;
  text-align: center;
  cursor: pointer; }
  .featuredArticle_Play p {
    margin-top: 16%;
    z-index: 10; }
  .featuredArticle_Play .start-video {
    height: 150px;
    display: block;
    margin-top: 16%;
    z-index: 10; }
    @media only screen and (max-width: 901px) {
      .featuredArticle_Play .start-video {
        margin-top: 5%; } }
    @media only screen and (max-width: 600px) {
      .featuredArticle_Play .start-video {
        margin-top: 4%; }
        .featuredArticle_Play .start-video svg {
          height: 120px; } }
  .featuredArticle_Play svg {
    fill: #ffffff;
    z-index: 10; }

.webinarHeader iframe {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 682px; }

.item .imageBackgroundLoader, .item .featureArticleTile, .item .featureArticleTile_bgOverlay, .item .featureArticleTile_label {
  border-radius: 0; }

li {
  margin-left: 2.5rem; }

ol {
  list-style: decimal; }
  ol ol {
    list-style: lower-alpha; }

ul {
  list-style: disc; }

.list__naked {
  list-style: none; }
  .list__naked > li {
    margin-left: 0; }

.moreLink {
  font-family: museo-slab;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 900; }
  .moreLink:after {
    content: ' \2192'; }

#homepage-hero {
  height: 68vh;
  min-height: 600px;
  position: relative;
  /*overflow:hidden;*/ }
  #homepage-hero #hero-bkgrd-image {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 900px;
    min-width: 50%;
    overflow: visible; }
    @media only screen and (max-width: 1200px) {
      #homepage-hero #hero-bkgrd-image {
        min-width: 60%; } }
    @media only screen and (min-width: 1600px) {
      #homepage-hero #hero-bkgrd-image img {
        max-height: 800px;
        max-width: 800px;
        left: 160px; } }
  @media only screen and (max-width: 1600px) {
    #homepage-hero #hero-bkgrd-image img {
      height: 100% !important; } }
  @media only screen and (max-width: 780px) {
    #homepage-hero {
      min-height: 100px;
      height: 58vh; }
      #homepage-hero #hero-bkgrd-image img {
        display: none; } }
  #homepage-hero .homepage-welcome {
    position: relative;
    padding-top: 10%;
    height: 100%;
    z-index: 10; }
    #homepage-hero .homepage-welcome h1 {
      line-height: 1; }
    #homepage-hero .homepage-welcome h2 {
      font-family: museo-slab;
      font-weight: 500;
      font-size: 14px;
      font-size: 0.875rem; }
      @media only screen and (min-width: 480px) and (max-width: 1920px) {
        #homepage-hero .homepage-welcome h2 {
          font-size: calc( 0.00694*100vw - -10.66667px); } }
      @media only screen and (min-width: 1920px) {
        #homepage-hero .homepage-welcome h2 {
          font-size: 24px;
          font-size: 1.5rem; } }
    #homepage-hero .homepage-welcome a {
      color: #ffffff !important; }

.homepage-cards {
  background: #f4f4f4;
  padding-bottom: 3rem; }
  @media only screen and (max-width: 464px) {
    .homepage-cards .search-input-box.fence__wide {
      width: 100%; } }
  .homepage-cards .col-sm-4,
  .homepage-cards .col-sm-8 {
    padding-right: 2rem;
    padding-left: 2rem; }
  .homepage-cards .featureArticleTile_label p {
    opacity: 0;
    color: white; }
  .homepage-cards .featureArticleTile {
    max-height: 500px;
    height: 500px; }
    .homepage-cards .featureArticleTile:hover p {
      opacity: 1; }

.homepage-featured {
  padding: 1.25rem 0;
  background-color: #f4f4f4; }

.tooltip {
  position: relative; }
  .tooltip::before, .tooltip::after {
    position: absolute;
    left: 50%;
    opacity: 0; }
  .tooltip::before {
    content: "";
    border-width: 0 8px 10px 8px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.5) transparent;
    margin-left: -19px;
    bottom: -14px; }
  .tooltip::after {
    content: attr(data-tooltip);
    background-color: rgba(0, 0, 0, 0.5);
    bottom: -15px;
    width: 140px;
    margin-left: -80px;
    font-size: 12px;
    padding: 10px;
    border-radius: 20px;
    color: white;
    transform: translateY(100%); }
  .tooltip:hover {
    opacity: 1 !important; }
    .tooltip:hover::before, .tooltip:hover::after {
      opacity: 1 !important; }

button, .button {
  cursor: pointer; }

.button {
  display: inline-block;
  background-color: #eb4234;
  color: #ffffff !important;
  font-weight: 900;
  line-height: 1;
  text-decoration: none;
  padding: 1.05rem 2.7rem;
  font-family: "Nunito Sans";
  font-weight: 700;
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  border-radius: 30px; }
  .button:hover {
    /*background-color: transparentize($c-red, 0.85);*/
    opacity: .6; }
  .button .svg-arrow-right-dims {
    width: 16px;
    height: 16px;
    display: inline;
    margin-top: -2px;
    margin-left: 8px;
    fill: #ffffff; }

.button__submit {
  padding: 1.5rem 3rem; }

.button__small {
  font-size: 14px;
  font-size: 0.875rem; }

.button__blue {
  background-color: #7ed5e7; }

.button__darkBlue {
  background-color: #1f4d5b; }

.button__green {
  background-color: #faaf4c; }

.button__podcast-badge {
  margin: 1.5rem;
  height: 40px; }
  .button__podcast-badge > img {
    height: 100%; }

.button__podcast-icon {
  display: inline-block;
  height: 40px;
  text-decoration: none; }
  .button__podcast-icon:hover {
    text-decoration: underline; }
  .button__podcast-icon > img,
  .button__podcast-icon > svg {
    display: inline-block;
    height: 100%;
    margin-right: 1.5rem; }

.inline-link {
  display: block;
  position: relative; }
  .inline-link img {
    display: inline; }

.contactForm,
.commentForm {
  background-color: #efefef; }
  .contactForm h2,
  .commentForm h2 {
    text-align: center; }
  .contactForm .row,
  .commentForm .row {
    margin: 2em 0; }
  .contactForm input,
  .contactForm textarea,
  .commentForm input,
  .commentForm textarea {
    border-radius: 12px;
    line-height: 1.5;
    padding: 1.5rem 1.8rem;
    color: #9a9a9d;
    width: 100%;
    font-family: "Nunito Sans";
    font-weight: 300; }

.commentList {
  margin-top: 3rem;
  border-top: 4px solid #f4f4f4; }
  .commentList .comment_header {
    margin-bottom: 1.5rem;
    padding-top: 3rem; }
  .commentList .comment_content {
    padding: 3rem;
    background-color: #efefef;
    border-radius: 3rem; }
  .commentList .comment_content.__pending {
    background-color: transparent;
    border: 1px dotted rgba(0, 0, 0, 0.2); }
  .commentList .comment_meta_status {
    font-size: .8em;
    margin-left: 50px;
    margin-top: 10px; }

.contactForm {
  background-color: white; }
  .contactForm input,
  .contactForm textarea {
    border: 1px solid #bdbdbd; }

input {
  color: #9a9a9d;
  font-family: "Nunito Sans";
  font-weight: 300; }

[type=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.input-shadow {
  padding: 7em 20% 4em;
  margin: 0 auto; }
  @media only screen and (max-width: 1100px) {
    .input-shadow {
      padding-left: 0;
      padding-right: 0; } }
  .input-shadow label {
    position: relative;
    border-radius: 30px;
    background: #ffffff;
    height: 30px;
    padding: 20px;
    box-shadow: 0px 0px 50px 30px rgba(154, 154, 157, 0.25); }
  .input-shadow input[type="text"],
  .input-shadow input[type="email"] {
    width: 90%;
    padding: 10px 10px 10px 34px;
    background: transparent; }

.search-container label:before {
  content: "";
  position: absolute;
  left: 20px;
  top: 2px;
  bottom: 0;
  width: 20px;
  background: url("/assets/icons/magnify-glass-right.png") center/contain no-repeat; }

ul.errors {
  display: none; }

.has-error .form-control,
.has-error .custom-file-label {
  border-color: #eb4234 !important; }

.has-error .form-check-label {
  color: #eb4234; }

.has-error ul.errors {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inherit; }

.has-error ul.errors > li {
  font-size: 80%;
  color: #eb4234;
  margin-top: .25rem; }

.alert {
  padding: 3rem; }

.alert-success {
  background-color: rgba(127, 238, 59, 0.85); }

.alert-danger {
  background-color: #eb4234;
  color: white; }

.form-horizontal .form-group .col-sm-3 select {
  min-width: 130px; }

.blockForm {
  border-radius: 30px;
  position: relative;
  color: #ffffff;
  margin-left: 1em;
  margin-right: 1em; }
  .blockForm .blockFormPanel {
    position: relative; }
    .blockForm .blockFormPanel h3, .blockForm .blockFormPanel p {
      color: #ffffff; }
    .blockForm .blockFormPanel .blockFormCta {
      font-size: 2rem;
      line-height: 1.3;
      margin: 0; }
  .blockForm .blockPadding {
    padding: 3.5rem 2rem 3.5rem 3.5rem; }
  .blockForm .hs-form {
    margin-top: 60px; }
    .blockForm .hs-form div.input {
      margin: 0;
      width: 80%; }
    .blockForm .hs-form .hs-submit {
      left: 70.59%; }
    @media only screen and (max-width: 1201px) {
      .blockForm .hs-form div.input {
        width: 100%; }
      .blockForm .hs-form .hs-submit {
        left: auto;
        top: 40px;
        width: auto;
        margin: 0px auto 40px;
        text-align: center; } }

.borderRadiusLeft {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important; }

/*.form__articleInline {

	margin: 0 0 $wideMargin;
	padding: $wideMargin;
	background: mix($c-grey, $c-white, 7%);
	border-top: 7px solid $c-plum;

	.formHeading {
		margin-top: 0;
		font-weight: $fw-bold;
	}

	.formField {

		width: 100%;

		> label {
			display: block;
			margin: $navMargin 0;
			@include altTitleFont;
			@include fontsize($smallFontSize);
			font-weight: $fw-bold;
		}

		& div.input {
			width: 100%;
		}

		& input, textarea {
			display: block;
			width: 100%;
			padding: $navMargin/2 $navMargin;
			background: $c-lighten;
			border: 1px dotted $c-darken;
		}

	}

	.formField__hidden {
		display: none !important;
	}

	.formSubmit {

		input[type=submit] {
			display: inline-block;
			margin-top: $navMargin;
			padding: $navMargin $defaultMargin;
			@include buttonColors($c-plum, $c-white);
			@include altTitleFont;
			@include t_smallish;
			font-weight: $fw-bold;
			text-transform: uppercase;
			line-height: 1;
			cursor: pointer;

		}

	}

}*/
/*
 * Background image tile
 */
.imageLoader {
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0); }
  .imageLoader.left, .imageLoader.right {
    width: 300px;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px; }
  .imageLoader.right {
    float: right;
    margin-right: 0px;
    margin-left: 10px; }

.imageLoader img {
  display: block;
  width: 100%; }

.imageLoader_image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out; }

.imageLoader_image.lazyloaded {
  opacity: 1; }

.imageLoader_previewImage {
  -webkit-filter: blur(10px);
  filter: blur(10px); }

.imageLoader_image.lazyloaded ~ .imageLoader_previewImage {
  opacity: 0; }

/*
 * Background image tile
 */
.imageBackgroundLoader {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  /*background-position: center center;*/
  z-index: 0; }
  .imageBackgroundLoader .imageBackgroundLoader_previewImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: #000000;
    z-index: 0;
    -webkit-filter: blur(10px);
    filter: blur(10px);
    transition: opacity 2s ease-in-out; }
  .imageBackgroundLoader.lazyloaded .imageBackgroundLoader_previewImage {
    opacity: 0; }

.imageCaption {
  font-size: 18px;
  font-size: 1.125rem;
  margin: 0em 0 2em;
  font-weight: 800; }

.mediaCredit {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  margin: 0em 0 2em; }

.imageCaption + .mediaCredit {
  margin-top: -2em; }

.siteHeader {
  position: fixed;
  display: block;
  z-index: 20;
  width: 100%;
  height: 150px;
  padding: 0.875rem 0;
  background-color: #ffffff;
  /*@include boxShadow;*/
  /*
   * Animations
   */
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: transform 0.5s, opacity 0.5s; }
  .siteHeader.toolbar__out {
    transform: translate3d(0, -150px, 0);
    opacity: 1; }

.page .siteHeader,
.listing .siteHeader {
  background-color: #efefef; }

.home .siteHeader {
  background-color: #ffffff; }

.mainNav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center; }

.mainNav_links {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  align-content: center;
  list-style: none; }
  .mainNav_links > li {
    margin-left: 0; }
  .mainNav_links > li {
    margin: 0 0 0 3rem; }
  .mainNav_links #mainNavItem__search {
    margin-left: 2em; }

.mainNav_link {
  text-decoration: none;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 900;
  text-transform: uppercase;
  padding-bottom: 10px; }
  .mainNav_link.__active:not(.__withIcon) {
    border-bottom: 3px solid currentColor; }
  .mainNav_link:hover {
    border-bottom: 3px solid; }
  .mainNav_link.color__blog {
    border-color: #eb4234 !important; }
  .mainNav_link.color__webinars {
    border-color: #7ed5e7 !important; }
  .mainNav_link.color__podcast {
    border-color: #fbb856 !important; }
  .mainNav_link.color__resources {
    border-color: #7fee3b !important; }
  .mainNav_link.__withIcon {
    border-color: #faaf4c !important; }
    .mainNav_link.__withIcon svg {
      width: 1.6em;
      height: 1.6em; }

.siteHeader_logo {
  line-height: 1; }

.siteHeader_logoLink {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin: 0 3rem 0 0;
  max-width: 200px; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .siteHeader_logoLink {
      max-width: calc( 13.88889vw + 133.33333px); } }
  @media only screen and (min-width: 1920px) {
    .siteHeader_logoLink {
      max-width: 400px; } }
  @media only screen and (max-width: 767px) {
    .siteHeader_logoLink {
      margin: 0; } }
  .siteHeader_logoLink svg {
    width: 100%;
    margin: 0; }

/*
 * Responsive nav treatment
 */
#mainNavItem__menu {
  min-width: 18px;
  vertical-align: 5%;
  text-decoration: none;
  border: none;
  position: absolute;
  right: 2.5%; }
  #mainNavItem__menu svg {
    width: 34px;
    stroke: #404042; }
  #mainNavItem__menu a {
    border: none; }
    #mainNavItem__menu a:hover {
      text-decoration: none;
      border: none; }

@media only screen and (min-width: 1070px) {
  #mainNavItem__menu {
    display: none; } }

@media only screen and (max-width: 1070px) {
  .mainNav_links {
    display: none;
    width: 0;
    margin: 0;
    padding: 0;
    height: 0; } }

.mobileNav {
  position: absolute;
  /*top: 140px;*/
  top: 0px;
  z-index: 20 !important;
  width: 100%;
  max-height: calc(100vh - 140px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 0 3rem 0;
  background: #ffffff;
  background: linear-gradient(180deg, #f4f4f4 0%, #cacaca 100%);
  box-shadow: 0 0 3rem rgba(64, 64, 66, 0.2);
  transform: translate(0, -1000px);
  opacity: 0.5;
  transition: transform .7s, opacity .7s; }

.close {
  display: block;
  text-align: right;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  font-size: 36px;
  font-size: 2.25rem;
  text-decoration: none; }

.mobileNav_item {
  margin: 0 0 0.875rem 0;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1.6;
  text-align: center; }
  .mobileNav_item a {
    text-decoration: none; }
  .mobileNav_item:last-child {
    margin-bottom: 0; }

.mobileNav.drawer--open {
  transform: translate(0, 0);
  opacity: 1; }

.mobileNav.drawer--open .mobileNav_item {
  transform: translate(0, 0);
  opacity: 1; }
  .mobileNav.drawer--open .mobileNav_item:nth-child(1) {
    transition-delay: 0.05s; }
  .mobileNav.drawer--open .mobileNav_item:nth-child(2) {
    transition-delay: 0.1s; }
  .mobileNav.drawer--open .mobileNav_item:nth-child(3) {
    transition-delay: 0.15s; }
  .mobileNav.drawer--open .mobileNav_item:nth-child(4) {
    transition-delay: 0.2s; }
  .mobileNav.drawer--open .mobileNav_item:nth-child(5) {
    transition-delay: 0.25s; }
  .mobileNav.drawer--open .mobileNav_item:nth-child(6) {
    transition-delay: 0.3s; }

.siteFooter {
  margin: 0;
  padding: 5rem 0 2rem;
  background-color: #dfdfdf;
  color: #404042;
  font-family: museo-slab;
  font-weight: 500; }
  @media only screen and (max-width: 767px) {
    .siteFooter {
      text-align: center; } }
  .siteFooter h4 {
    margin: 0 0 10px 0; }

.icon.siteFooter_cjLogo {
  width: 10em;
  height: 6em; }

.siteFooter_logoLink {
  display: inline-block;
  max-width: 200px;
  width: 10em;
  height: 6em; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .siteFooter_logoLink {
      max-width: calc( 13.88889vw + 133.33333px); } }
  @media only screen and (min-width: 1920px) {
    .siteFooter_logoLink {
      max-width: 400px; } }
  .siteFooter_logoLink svg {
    width: 100%; }

.siteFooter_socialLinks {
  margin: 3rem 0 4.5rem;
  text-align: center; }
  .siteFooter_socialLinks .siteFooter_socialIcon {
    width: 22px;
    height: 22px;
    fill: #dfdfdf; }
  .siteFooter_socialLinks .iconCircle {
    background-color: #ffffff;
    margin-right: 1.2em; }
  .siteFooter_socialLinks .iconCircle:hover {
    background-color: #808080; }

.siteFooter_linkList {
  font-family: "Nunito Sans";
  font-weight: 300;
  line-height: 1.2; }
  .siteFooter_linkList li {
    margin: 0 0 5px;
    list-style: none; }
  .siteFooter_linkList a {
    text-decoration: none; }
  .siteFooter_linkList a:hover {
    text-decoration: underline; }

.siteFooter_reframeInfo {
  font-family: "Nunito Sans";
  font-weight: 300;
  text-align: left; }
  @media only screen and (max-width: 767px) {
    .siteFooter_reframeInfo {
      text-align: center;
      border-top: 1px dotted #404042;
      margin-top: 2.5rem;
      padding-top: 2.5rem; } }
  .siteFooter_reframeInfo .siteFooter_reframeInfo--logo svg {
    width: 125px;
    height: 40px; }

.icon.siteFooter_reframeLogo {
  width: 123px;
  height: 42px; }

.programsFooter {
  background-color: #b5b5b5;
  color: #ffffff;
  padding: 2.5rem 0; }

.programsFooter_programLinks_intro {
  font-family: museo-slab;
  font-weight: 500;
  font-size: 14px;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0.875rem;
  text-align: center; }

.programsFooter_programLinks {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 1;
  -moz-box-flex: 0;
  -moz-flex: 0 0 1;
  -ms-flex: 0 0 1;
  flex: 0 0 1;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: center; }
  @media only screen and (min-width: 1200px) {
    .programsFooter_programLinks {
      justify-content: space-around; } }

.programsFooter_programLinks_link {
  display: inline-block;
  flex: 1;
  min-width: 125px;
  margin: 0.875rem 3rem; }
  .programsFooter_programLinks_link .siteFooter_programIcon {
    height: 30px;
    width: 60px;
    fill: rgba(255, 255, 255, 0.5); }
    .programsFooter_programLinks_link .siteFooter_programIcon.__today {
      width: 124px;
      height: 42px;
      top: 4px; }
    .programsFooter_programLinks_link .siteFooter_programIcon.__groundwork {
      width: 144px;
      height: 52px;
      top: -7px; }
    .programsFooter_programLinks_link .siteFooter_programIcon.__familyfire {
      width: 114px;
      height: 54px; }
    .programsFooter_programLinks_link .siteFooter_programIcon.__kidscorner {
      width: 92px;
      height: 54px; }
    .programsFooter_programLinks_link .siteFooter_programIcon.__thinkchristian {
      width: 177px;
      height: 47px;
      top: 4px; }
    .programsFooter_programLinks_link .siteFooter_programIcon:hover {
      fill: #ffffff; }

.attributionFooter {
  background-color: #808080;
  color: #ffffff;
  padding: 1.25rem 0;
  font-family: "Nunito Sans";
  font-weight: 300;
  font-size: 14px;
  font-size: 0.875rem; }
  .attributionFooter, .attributionFooter * {
    transition: color 0.2s linear 0; }
  .attributionFooter p {
    margin-top: .1em; }
  @media only screen and (max-width: 480px) {
    .attributionFooter p {
      text-align: center; } }

.panelContainer {
  display: table;
  width: 100%; }
  @media only screen and (max-width: 601px) {
    .panelContainer {
      display: block; } }

.panel, .panel--half {
  margin: 0;
  padding: 1.25rem;
  display: table-cell;
  height: 100%;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover !important; }

.panel--oneThird {
  margin: 0;
  padding: 1.25rem;
  display: table-cell;
  height: 100%;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover !important;
  width: 33.333%; }
  @media only screen and (max-width: 1201px) {
    .panel--oneThird {
      width: 50%; } }
  @media only screen and (max-width: 601px) {
    .panel--oneThird {
      width: 100%;
      display: block; } }

.panel--twoThirds {
  margin: 0;
  padding: 1.25rem;
  display: table-cell;
  height: 100%;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover !important;
  width: 66.666%; }
  @media only screen and (max-width: 1201px) {
    .panel--twoThirds {
      width: 50%; } }
  @media only screen and (max-width: 601px) {
    .panel--twoThirds {
      width: 100%;
      display: block; } }

.panel--half {
  width: 50%; }
  @media only screen and (max-width: 601px) {
    .panel--half {
      width: 100%;
      display: block; } }

.panel--logo {
  padding: 5%;
  text-align: center;
  vertical-align: middle; }

.panel--image {
  min-height: 200px;
  position: relative; }
  @media only screen and (max-width: 601px) {
    .panel--image {
      padding-top: 40%; } }

.panel--extraPadding {
  padding: 2.5rem 1.875rem; }

.masthead {
  text-align: center; }
  .masthead h1 {
    padding: 0;
    margin: 0; }

.page .masthead,
.listing .masthead {
  background-color: #efefef; }

.mastheadImage {
  position: relative;
  margin: 0 0 3.75rem;
  /*max-height:650px;*/ }

.readMore {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  line-height: 1.4;
  font-weight: 900;
  font-size: 16.8px;
  font-size: 1.05rem;
  color: #73cfe4; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .readMore {
      font-size: calc( 0.00694*100vw - -16.66667px); } }
  @media only screen and (min-width: 1920px) {
    .readMore {
      font-size: 30px;
      font-size: 1.875rem; } }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .readMore {
      font-size: calc( 0.00222*100vw - -15.73333px); } }
  @media only screen and (min-width: 1920px) {
    .readMore {
      font-size: 20px;
      font-size: 1.25rem; } }

/*
 * Page loading animation
 */
.page__loading {
  transform: scale(0.8);
  opacity: 0.1; }

.page__loaded {
  transform: scale(1);
  opacity: 1;
  background-color: transparent; }

.cat-link {
  color: black !important; }

.cat-box {
  position: relative;
  text-align: center;
  background-color: #f4f4f4;
  padding: 40px 0 60px;
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  border-radius: 20px;
  margin: 0 .25em 30px; }
  .cat-box .cat-icon {
    display: block;
    fill: none;
    stroke: #000000;
    max-width: 120px;
    margin: 0 auto; }
  .cat-box .churchjuice-icon {
    max-width: 80px;
    margin: 0 auto;
    display: block; }
  .cat-box:hover {
    background-color: #dfdfdf; }

.searchResults h4 {
  margin-bottom: 0; }

.searchResults p {
  margin-top: 0px; }

.searchResults .meta {
  font-size: 17px;
  font-size: 1.0625rem; }

.subscribe-cta {
  background-color: #ffffff;
  overflow: hidden;
  max-height: 800px; }
  .subscribe-cta .fence__wide {
    position: relative;
    min-height: 1400px; }
  .subscribe-cta .subscribe-cta-circle {
    position: absolute;
    height: 900px; }
    .subscribe-cta .subscribe-cta-circle.cta-orange-circle {
      left: -333px;
      top: -260px; }
    .subscribe-cta .subscribe-cta-circle.cta-red-circle {
      left: -495px;
      top: 280px; }
  .subscribe-cta .subscribe-cta-content {
    z-index: 10;
    text-align: center;
    position: relative;
    padding-top: 180px; }
    .subscribe-cta .subscribe-cta-content h4 {
      font-family: museo-slab;
      font-weight: 500;
      font-size: 16px;
      font-size: 1rem; }
      @media only screen and (min-width: 480px) and (max-width: 1920px) {
        .subscribe-cta .subscribe-cta-content h4 {
          font-size: calc( 0.00556*100vw - -13.33333px); } }
      @media only screen and (min-width: 1920px) {
        .subscribe-cta .subscribe-cta-content h4 {
          font-size: 24px;
          font-size: 1.5rem; } }
  .subscribe-cta .button__blue {
    position: absolute;
    right: -2px;
    top: 0;
    padding: 27px 2.7rem;
    border-radius: 40px; }

.podcastHeader {
  background-color: #fbb856;
  color: #ffffff; }

.resourceHeader {
  background-color: #7fee3b;
  color: #ffffff; }

.webinarHeader {
  background-color: #7ed5e7;
  color: #ffffff; }

.grayHeader {
  background-color: #f4f4f4; }

.whiteHeader {
  background-color: #ffffff; }

.articleRow {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  height: 100%; }

@media only screen and (max-width: 48em) {
  .articleRow {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem; } }

.authorPage .authorImage,
.authorMeta .authorImage {
  width: 100%;
  height: 100%;
  max-height: 220px;
  max-width: 220px;
  margin: 0 auto; }
  .authorPage .authorImage .imageLoader,
  .authorMeta .authorImage .imageLoader {
    height: 100%;
    border-radius: 50%; }
    .authorPage .authorImage .imageLoader img,
    .authorMeta .authorImage .imageLoader img {
      border-radius: 50%;
      height: 100%; }

.authorPage .authorSocials,
.authorMeta .authorSocials {
  list-style: none;
  margin-bottom: 40px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: baseline;
  align-content: center; }
  .authorPage .authorSocials > li,
  .authorMeta .authorSocials > li {
    margin-left: 0; }
  .authorPage .authorSocials li,
  .authorMeta .authorSocials li {
    margin: 0 5px 0 5px; }
    .authorPage .authorSocials li svg,
    .authorMeta .authorSocials li svg {
      fill: #52abb8;
      width: 30px;
      height: 30px; }
  .authorPage .authorSocials li.socialAuthor svg,
  .authorPage .authorSocials li.socialWebsite svg,
  .authorMeta .authorSocials li.socialAuthor svg,
  .authorMeta .authorSocials li.socialWebsite svg {
    width: 26px;
    height: 26px; }

.authorPage h4,
.authorMeta h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  line-height: 1.4;
  font-weight: 900;
  font-size: 16.8px;
  font-size: 1.05rem; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .authorPage h4,
    .authorMeta h4 {
      font-size: calc( 0.00694*100vw - -16.66667px); } }
  @media only screen and (min-width: 1920px) {
    .authorPage h4,
    .authorMeta h4 {
      font-size: 30px;
      font-size: 1.875rem; } }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .authorPage h4,
    .authorMeta h4 {
      font-size: calc( 0.00222*100vw - -15.73333px); } }
  @media only screen and (min-width: 1920px) {
    .authorPage h4,
    .authorMeta h4 {
      font-size: 20px;
      font-size: 1.25rem; } }

.articleMeta {
  position: -webkit-sticky;
  position: sticky;
  top: 200px;
  align-self: flex-start;
  margin: 0 auto;
  text-align: center; }
  .articleMeta ol {
    text-align: left;
    margin-left: 5%;
    list-style: none; }
    @media only screen and (min-width: 1200px) {
      .articleMeta ol {
        margin-left: 14%; } }
    .articleMeta ol li + li {
      margin-top: 5px; }
    .articleMeta ol li {
      list-style: none;
      margin-left: 0;
      font-family: "Nunito Sans";
      font-weight: 700;
      font-size: 20px;
      color: #9a9a9d;
      line-height: 1.5; }
      .articleMeta ol li a {
        font-size: 20px;
        font-family: "Nunito Sans";
        font-weight: 700;
        line-height: 1;
        color: #9a9a9d; }
        .articleMeta ol li a:hover, .articleMeta ol li a.active {
          color: #73cfe4; }

@media only screen and (max-width: 48em) {
  .articleMeta {
    position: relative;
    margin: 20px 0;
    padding-top: 20px;
    top: 0;
    text-align: left;
    border-top: 1px solid #67c4d2; }
    .articleMeta .authorImage {
      height: 200px;
      width: 200px;
      float: left;
      margin-right: 20px; }
    .articleMeta .authorSocials {
      justify-content: start; } }

.articleImageFrame {
  padding: 4% 0 5%;
  /*padding-bottom: calc(0.7% + 4px);*/
  position: relative;
  margin: 0 0 3.75rem; }

.articleAudioPlayer {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.article_teaser {
  display: block;
  position: relative;
  margin: 3.75rem 0 3rem;
  text-align: center; }

.article_teaser:after {
  display: block;
  margin: 9rem auto 9rem;
  width: 7%;
  height: 10px;
  content: ' ';
  /*background: $c-blueGray; // default, overridden by page theme*/
  /*@include __bg-themeDust;*/
  border-radius: 1px; }
  @media only screen and (max-width: 901px) {
    .article_teaser:after {
      margin: 6rem auto 6rem;
      width: 14%; } }

.article_pullQuote {
  padding: 2.5rem;
  text-align: center; }
  .article_pullQuote blockquote {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important; }
  .article_pullQuote .article_pullQuote_quote {
    font-size: 20px;
    font-size: 1.25rem;
    font-family: "Nunito Sans";
    font-weight: 900;
    font-weight: 900;
    line-height: 1.4; }
    @media only screen and (min-width: 480px) and (max-width: 1920px) {
      .article_pullQuote .article_pullQuote_quote {
        font-size: calc( 0.01389*100vw - -13.33333px); } }
    @media only screen and (min-width: 1920px) {
      .article_pullQuote .article_pullQuote_quote {
        font-size: 40px;
        font-size: 2.5rem; } }
  .article_pullQuote .article_pullQuote_attribution {
    margin-top: 3rem; }

.articleAuthors {
  margin: 2.5rem 0 5rem;
  padding: 2.5rem 0 0;
  border-top: 3px solid #bed0d3;
  font-size: 17px;
  font-size: 1.0625rem; }

.articleAuthors_profile {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap; }

.articleAuthors_profileImage {
  width: 142px;
  flex-basis: 142px;
  flex-grow: 1;
  margin-bottom: 3rem; }
  @media only screen and (min-width: 601px) {
    .articleAuthors_profileImage {
      margin-right: 2.5rem; } }

.articleAuthors_content {
  flex-basis: 442px;
  flex-grow: 11; }

.articleAuthors_profileImage, .articleAuthors_content {
  text-align: center; }
  @media only screen and (min-width: 694px) {
    .articleAuthors_profileImage, .articleAuthors_content {
      text-align: left; } }

.articleAuthors_moreLink {
  font-family: museo-slab;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 900; }
  .articleAuthors_moreLink:after {
    content: ' \2192'; }

/*
 * Headings & Typography
 */
.article_body h2 {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  line-height: 1.4;
  margin: 3.75rem 0 2.5rem; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .article_body h2 {
      font-size: calc( 0.01389*100vw - -13.33333px); } }
  @media only screen and (min-width: 1920px) {
    .article_body h2 {
      font-size: 40px;
      font-size: 2.5rem; } }

.article_body h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  line-height: 1.4;
  margin: 2.5rem 0 3rem; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .article_body h3 {
      font-size: calc( 0.00694*100vw - -16.66667px); } }
  @media only screen and (min-width: 1920px) {
    .article_body h3 {
      font-size: 30px;
      font-size: 1.875rem; } }

.article_body h4 {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: museo-slab;
  font-weight: 500;
  font-weight: 900;
  line-height: 1.4;
  font-size: 16.8px;
  font-size: 1.05rem;
  margin: 3rem 0; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .article_body h4 {
      font-size: calc( 0.00694*100vw - -16.66667px); } }
  @media only screen and (min-width: 1920px) {
    .article_body h4 {
      font-size: 30px;
      font-size: 1.875rem; } }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .article_body h4 {
      font-size: calc( 0.00222*100vw - -15.73333px); } }
  @media only screen and (min-width: 1920px) {
    .article_body h4 {
      font-size: 20px;
      font-size: 1.25rem; } }

.article_body p:first-of-type {
  font-size: 26px;
  font-size: 1.625rem; }

.articleContent_video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  margin: 3rem auto;
  width: 100%;
  border: 0.875rem solid #bed0d3; }
  .articleContent_video > .articleContent_video_player {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.articleContent_embed {
  position: relative;
  margin: 3rem auto;
  width: 100%;
  border: 0.875rem solid #bed0d3; }

.article_topics {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 900; }

.articleActions_item {
  margin: 0 2.5rem 3rem;
  font-family: museo-slab;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 900;
  text-transform: uppercase; }
  .articleActions_item.share {
    color: #537bbd; }
  .articleActions_item.tweet {
    color: #78cdf0; }
  .articleActions_item a {
    text-decoration: none; }
  .articleActions_item .articleActions_item_label {
    margin-left: 1.5rem; }
  .articleActions_item a:hover .articleActions_item_label {
    text-decoration: underline; }

/*
 * Article theme
 */
.article_body blockquote {
  margin-left: 3rem;
  padding: 0 3rem;
  border-left: 7px solid rgba(0, 0, 0, 0.2);
  /*@include __border-themeDust;*/ }

.progress-bar {
  height: 0px; }

.article .progress-bar {
  background: url("../icons/progress-bar.png") 100%;
  background-repeat: no-repeat;
  width: var(--scroll);
  position: absolute;
  top: 150px;
  left: 0;
  height: 6px;
  z-index: 1;
  max-width: 1920px; }

.authorPage .authorHeader {
  position: relative;
  margin: 0 auto 2rem; }
  .authorPage .authorHeader .authorImage {
    margin: 0 auto 1rem;
    max-height: 360px;
    max-width: 360px; }
  .authorPage .authorHeader h1 {
    margin: 0 auto; }

.listingTiles .imageLoader,
.listingTiles img {
  border-radius: 30px; }

.listingTiles .subscribe-cta img {
  border-radius: 0; }

.listingTiles .featuredArticle .listingTile {
  margin-top: 0 !important; }

.listingTiles .col-xs-12 {
  padding-left: 1em;
  padding-right: 1em; }

.listingTiles h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  line-height: 1.4;
  margin: 1% 0; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .listingTiles h3 {
      font-size: calc( 0.00694*100vw - -16.66667px); } }
  @media only screen and (min-width: 1920px) {
    .listingTiles h3 {
      font-size: 30px;
      font-size: 1.875rem; } }

.listingTiles .postMeta {
  font-size: 17px;
  font-size: 1.0625rem;
  margin-bottom: 20px; }
  .listingTiles .postMeta svg {
    width: 20px;
    height: 20px;
    fill: #404042;
    margin-bottom: 8px;
    margin-right: 8px; }
  .listingTiles .postMeta .metaComments {
    margin-left: 28px; }

.listingTiles .listingTile {
  border-radius: 30px;
  max-height: 500px;
  height: 500px;
  display: block;
  position: relative;
  overflow: hidden; }
  .listingTiles .listingTile.box_color__blog:hover .listingTile_bgOverlay {
    background-color: rgba(255, 66, 52, 0.85);
    opacity: 1; }
  .listingTiles .listingTile.box_color__webinar:hover .listingTile_bgOverlay {
    background-color: rgba(126, 213, 231, 0.85);
    opacity: 1; }
  .listingTiles .listingTile.box_color__resource:hover .listingTile_bgOverlay {
    background-color: rgba(127, 238, 59, 0.85);
    opacity: 1; }
  .listingTiles .listingTile.box_color__podcast:hover .listingTile_bgOverlay {
    background-color: rgba(251, 184, 86, 0.85);
    opacity: 1; }
  .listingTiles .listingTile .imageBackgroundLoader {
    border-radius: 30px; }
  .listingTiles .listingTile .listingTile_bgOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    height: 100.3141596%;
    width: 100%;
    border-radius: 30px;
    pointer-events: none; }
  .listingTiles .listingTile .listingTile_label {
    border-radius: 30px;
    color: #ffffff;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 3; }
    .listingTiles .listingTile .listingTile_label .listingBottom {
      position: absolute;
      bottom: 4rem;
      transition: bottom 350ms;
      transition-timing-function: ease-in-out; }
    .listingTiles .listingTile .listingTile_label .listingTeaser {
      position: absolute;
      margin: 2rem;
      top: 30rem;
      transition: top 350ms;
      transition-timing-function: ease-in-out; }
    .listingTiles .listingTile .listingTile_label h2 {
      font-size: 20px;
      font-size: 1.25rem;
      font-family: "Nunito Sans";
      font-weight: 900;
      font-weight: 900;
      line-height: 1.4;
      z-index: 3;
      color: #ffffff;
      margin: 1rem 2rem 0; }
      @media only screen and (min-width: 480px) and (max-width: 1920px) {
        .listingTiles .listingTile .listingTile_label h2 {
          font-size: calc( 0.00694*100vw - -16.66667px); } }
      @media only screen and (min-width: 1920px) {
        .listingTiles .listingTile .listingTile_label h2 {
          font-size: 30px;
          font-size: 1.875rem; } }
    .listingTiles .listingTile .listingTile_label h5 {
      z-index: 3;
      color: #ffffff;
      font-size: 20px;
      font-size: 1.25rem;
      font-family: "Nunito Sans";
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.5em;
      margin: 4rem 2rem 6rem; }
      @media only screen and (max-width: 1500px) {
        .listingTiles .listingTile .listingTile_label h5 {
          font-size: 18px;
          font-size: 1.125rem; } }
  @media only screen and (max-width: 1500px) and (min-width: 480px) and (max-width: 1920px) {
    .listingTiles .listingTile .listingTile_label h5 {
      font-size: calc( 0*100vw - -18px); } }
  @media only screen and (max-width: 1500px) and (min-width: 1920px) {
    .listingTiles .listingTile .listingTile_label h5 {
      font-size: 18px;
      font-size: 1.125rem; } }
      @media only screen and (max-width: 600px) {
        .listingTiles .listingTile .listingTile_label h5 {
          font-size: 14px;
          font-size: 0.875rem; } }
  @media only screen and (max-width: 600px) and (min-width: 480px) and (max-width: 1920px) {
    .listingTiles .listingTile .listingTile_label h5 {
      font-size: calc( 0*100vw - -14px); } }
  @media only screen and (max-width: 600px) and (min-width: 1920px) {
    .listingTiles .listingTile .listingTile_label h5 {
      font-size: 14px;
      font-size: 0.875rem; } }
    .listingTiles .listingTile .listingTile_label .postMeta {
      margin: 0 2rem;
      z-index: 10; }
      .listingTiles .listingTile .listingTile_label .postMeta span {
        z-index: 10;
        color: #ffffff;
        font-size: 17px;
        font-size: 1.0625rem; }
        .listingTiles .listingTile .listingTile_label .postMeta span svg {
          fill: #ffffff;
          z-index: 10; }
      .listingTiles .listingTile .listingTile_label .postMeta .metaIcon_space {
        margin-left: 30px; }
      .listingTiles .listingTile .listingTile_label .postMeta .metaIcon_play-circle svg {
        width: 48px;
        height: 48px;
        margin-top: 8px; }
      .listingTiles .listingTile .listingTile_label .postMeta .metaIcon_downloads svg {
        width: 32px;
        height: 32px;
        stroke: #ffffff;
        fill: transparent; }

.listingTiles .hoverTile a:hover {
  opacity: 1 !important; }
  .listingTiles .hoverTile a:hover img {
    opacity: 1 !important; }
  .listingTiles .hoverTile a:hover .listingBottom {
    bottom: 22rem; }
  .listingTiles .hoverTile a:hover .listingTeaser {
    top: 8rem; }

@media only screen and (max-width: 1201px) {
  .listingTiles .listingTile .listingTile_label .listingBottom,
  .listingTiles .listingTile .listingTile_label .listingTeaser {
    top: auto; }
  .listingTiles .listingTile .listingTile_label .listingBottom {
    margin-top: 20px; }
  .listingTiles .hoverTile .listingTile_label .listingBottom,
  .listingTiles .hoverTile .listingTile_label .listingTeaser {
    position: relative;
    bottom: 15px; }
  .listingTiles .hoverTile a:hover .listingBottom,
  .listingTiles .hoverTile a:hover .listingTeaser {
    bottom: 15px;
    top: auto;
    opacity: .8; } }

@media only screen and (min-width: 768px) and (max-width: 1201px) {
  .listingTiles .listingTeaser,
  .listingTiles .listingTeaser p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.5; } }
  @media only screen and (min-width: 768px) and (max-width: 1201px) and (min-width: 480px) and (max-width: 1920px) {
    .listingTiles .listingTeaser,
    .listingTiles .listingTeaser p {
      font-size: calc( 0.00625*100vw - -10px); } }
  @media only screen and (min-width: 768px) and (max-width: 1201px) and (min-width: 1920px) {
    .listingTiles .listingTeaser,
    .listingTiles .listingTeaser p {
      font-size: 22px;
      font-size: 1.375rem; } }

@media only screen and (max-width: 767px) {
  .listingTiles .listingTeaser,
  .listingTiles .listingTeaser p {
    font-size: .85em; } }

@media only screen and (max-width: 480px) {
  .listingTiles .listingTile .listingTile_label .listingTeaser {
    margin-top: 10px; }
    .listingTiles .listingTile .listingTile_label .listingTeaser p {
      font-size: 15px;
      font-size: 0.9375rem;
      line-height: 1.5; } }
    @media only screen and (max-width: 480px) and (min-width: 480px) and (max-width: 1920px) {
      .listingTiles .listingTile .listingTile_label .listingTeaser p {
        font-size: calc( 0.00486*100vw - -12.66667px); } }
    @media only screen and (max-width: 480px) and (min-width: 1920px) {
      .listingTiles .listingTile .listingTile_label .listingTeaser p {
        font-size: 22px;
        font-size: 1.375rem; } }

.load-more-spinner {
  display: none; }

.load-more-btn {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Nunito Sans";
  font-weight: 900;
  font-weight: 900;
  line-height: 1.4;
  z-index: 12;
  color: #7ed5e7;
  cursor: pointer; }
  @media only screen and (min-width: 480px) and (max-width: 1920px) {
    .load-more-btn {
      font-size: calc( 0.01389*100vw - -13.33333px); } }
  @media only screen and (min-width: 1920px) {
    .load-more-btn {
      font-size: 40px;
      font-size: 2.5rem; } }

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-fall,
.la-ball-fall > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.la-ball-fall {
  display: block;
  font-size: 0;
  color: #fff; }

.la-ball-fall.la-dark {
  color: #333; }

.la-ball-fall > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor; }

.la-ball-fall {
  width: 54px;
  height: 18px; }

.la-ball-fall > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: ball-fall 1s ease-in-out infinite;
  -moz-animation: ball-fall 1s ease-in-out infinite;
  -o-animation: ball-fall 1s ease-in-out infinite;
  animation: ball-fall 1s ease-in-out infinite; }

.la-ball-fall > div:nth-child(1) {
  -webkit-animation-delay: -200ms;
  -moz-animation-delay: -200ms;
  -o-animation-delay: -200ms;
  animation-delay: -200ms; }

.la-ball-fall > div:nth-child(2) {
  -webkit-animation-delay: -100ms;
  -moz-animation-delay: -100ms;
  -o-animation-delay: -100ms;
  animation-delay: -100ms; }

.la-ball-fall > div:nth-child(3) {
  -webkit-animation-delay: 0ms;
  -moz-animation-delay: 0ms;
  -o-animation-delay: 0ms;
  animation-delay: 0ms; }

.la-ball-fall.la-sm {
  width: 26px;
  height: 8px; }

.la-ball-fall.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px; }

.la-ball-fall.la-2x {
  width: 108px;
  height: 36px; }

.la-ball-fall.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px; }

.la-ball-fall.la-3x {
  width: 162px;
  height: 54px; }

.la-ball-fall.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px; }

/*
 * Animation
 */
@-webkit-keyframes ball-fall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-145%);
    transform: translateY(-145%); }
  10% {
    opacity: .5; }
  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  80% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  90% {
    opacity: .5; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(145%);
    transform: translateY(145%); } }

@-moz-keyframes ball-fall {
  0% {
    opacity: 0;
    -moz-transform: translateY(-145%);
    transform: translateY(-145%); }
  10% {
    opacity: .5; }
  20% {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0); }
  80% {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0); }
  90% {
    opacity: .5; }
  100% {
    opacity: 0;
    -moz-transform: translateY(145%);
    transform: translateY(145%); } }

@-o-keyframes ball-fall {
  0% {
    opacity: 0;
    -o-transform: translateY(-145%);
    transform: translateY(-145%); }
  10% {
    opacity: .5; }
  20% {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0); }
  80% {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0); }
  90% {
    opacity: .5; }
  100% {
    opacity: 0;
    -o-transform: translateY(145%);
    transform: translateY(145%); } }

@keyframes ball-fall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-145%);
    -moz-transform: translateY(-145%);
    -o-transform: translateY(-145%);
    transform: translateY(-145%); }
  10% {
    opacity: .5; }
  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  80% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  90% {
    opacity: .5; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(145%);
    -moz-transform: translateY(145%);
    -o-transform: translateY(145%);
    transform: translateY(145%); } }

.articleAudioPlayer {
  background-color: #404042; }

.hs-form {
  /*.hs_error_rollup > .hs-error-msgs {
		margin-top:40px;
	}*/ }
  .hs-form label span {
    display: none !important;
    content: ""; }
  .hs-form div.input {
    width: 70%;
    position: relative;
    border-radius: 30px;
    background: #ffffff;
    padding: 3px 20px;
    margin: 0 auto;
    box-shadow: 0px 0px 50px 30px rgba(154, 154, 157, 0.25); }
    @media only screen and (max-width: 1100px) {
      .hs-form div.input {
        padding-left: 0;
        padding-right: 0;
        width: 100%; } }
  .hs-form .hs-input {
    width: 100%;
    padding: 10px 10px 10px 34px;
    background: transparent;
    position: relative;
    top: 1px; }
  .hs-form .hs-submit {
    position: relative;
    top: -60px;
    width: 243px;
    left: 75.59%; }
    .hs-form .hs-submit input.hs-button {
      cursor: pointer;
      display: inline-block;
      background-color: #7ed5e7;
      color: #ffffff;
      font-weight: 900;
      line-height: 1;
      text-decoration: none;
      padding: 1.5rem 3rem;
      font-family: "Nunito Sans";
      font-weight: 700;
      font-size: 12px;
      font-size: 0.75rem;
      text-transform: uppercase;
      letter-spacing: 3px;
      border-radius: 30px;
      position: relative; }
      .hs-form .hs-submit input.hs-button:hover {
        background-color: #52abb8; }
  @media only screen and (max-width: 901px) {
    .hs-form .hs-submit {
      left: auto;
      top: 40px;
      width: auto; } }
  .hs-form .hs-error-msgs {
    position: absolute;
    left: 16%;
    margin-top: 10px;
    color: #eb4234; }
    .hs-form .hs-error-msgs li + li {
      margin-top: 30px; }
  .hs-form .hs_error_rollup {
    display: none; }

/*.hubspotForm__theBigOne {

	& > .hubspotForm_heading {
		font-weight: $fw-bold;
	}

	& > .hubspotForm_instructions {
		@include t_smallish;
	}

	.hbspt-form {
		margin-bottom: $wideMargin;
	}

	.hs_email, .hs_tc_category_interest, .hs_submit {

		width: 100%;
		margin: 0 0 $defaultMargin;

		> label {
			display: block;
			margin: $navMargin 0;
			@include altTitleFont;
			font-weight: $fw-bold;
		}

		& div.input {
			width: 100%;
			// padding: 0 $wideMargin;
		}

	}

	.hs_email {

		& input {
			display: block;
			width: 100%;
			padding: $navMargin;
			background: $c-plumDust;
		}

	}

	ul.inputs-list.multi-container
	{
		@include nakedList;
	}

	input.hs-input[type=checkbox] {

		display: inline-block;
		position: relative;
		width: 32px;
		height: 30px;
		top: 7px;
		margin-right: $defaultMargin;

		-webkit-appearance: none;

		   -moz-appearance: none;

		        appearance: none;

		&:after {
			content: url('/assets/icons/checkbox-unchecked.svg');
		}

		&:checked:after {
			content: url('/assets/icons/checkbox-checked.svg');
		}


	}

	.hs_submit input[type=submit] {
		display: inline-block;
		margin-top: $navMargin;
		padding: $navMargin $defaultMargin;
		@include buttonColors($c-plum, $c-white);
		@include altTitleFont;
		font-weight: $fw-bold;
		text-transform: uppercase;
		line-height: 1;
		cursor: pointer;
	}

	ul.hs-error-msgs {

		@include nakedList;
		@include altTitleFont;

		padding: $navMargin 0 $navMargin;

		li {
			padding: $navMargin $defaultMargin;
			color: $c-rust;
			background: $c-rustDust;
			font-weight: $fw-bold;
		}

	}

	.hbspt-form .submitted-message {
		padding: $navMargin;
		color: $c-darkGreen;
		background: $c-greenDust;
		font-weight: $fw-bold;
	}

}

label.hs-form-checkbox-display {

	& > span {
		@include titleFont;
		font-size: 24px;
	}

	// All Categories
	li:nth-child(1) & {
		color: $c-black;
	}

	// Music
	li:nth-child(2) & {
		color: $c-music;
	}

	// Movies
	li:nth-child(3) & {
		color: $c-movies;
	}

	// TV
	li:nth-child(4) & {
		color: $c-tv;
	}

	// Games
	li:nth-child(5) & {
		color: $c-games;
	}

	// Online
	li:nth-child(6) & {
		color: $c-online;
	}

	// Culture At Large
	li:nth-child(7) & {
		color: $c-etc;
	}

}


.hubspotForm__articleInline {

	@extend .form__articleInline;

	& > .hubspotForm_heading {

		@extend .formHeading;

	}

	& > .hubspotForm_instructions {
		margin-top: $defaultMargin/2;
		@include t_smallish;
	}

	.hbspt-form {
		display: block;
	}

	.hs_email {

		@extend .formField;

	}

	.hs_submit {

		@extend .formSubmit;

	}

	ul.hs-error-msgs {

		@include nakedList;
		@include altTitleFont;
		@include fontsize($smallFontSize);

		padding: $navMargin 0;

		li {
			padding: $navMargin $defaultMargin;
			color: $c-rust;
			background: $c-rustDust;
			font-weight: $fw-bold;
		}

	}

	.hbspt-form .submitted-message {
		padding: $navMargin;
		margin-top: $defaultMargin;
		color: $c-darkGreen;
		background: $c-greenDust;
		font-weight: $fw-bold;
	}

}


.hubspotForm__actionBar {

	display: -webkit-box;

	display: -ms-flexbox;

	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: stretch;
	max-height: 55px;
	overflow: hidden;

	& > form { // .hs-form
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		    -ms-flex-flow: row;
		        flex-flow: row;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		margin: 0 $defaultMargin 0 $defaultMargin/2;
		padding: 3px;
		background: $c-greyDust;
	}

	& > .hubspotForm_heading {
		display: none;
	}

	& > .hubspotForm_instructions {
		display: none;
	}

	.hs_email {

		display: -webkit-box;

		display: -ms-flexbox;

		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		    -ms-flex-flow: row nowrap;
		        flex-flow: row nowrap;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;

		> label {
			display: none;
		}

		& div.input {
			display: inline-block;
			margin: 0 $defaultMargin/2 0 0;
		}

		& input {
			display: inline-block;
			width: 100%;
			padding: $navMargin/2 $navMargin;
			background: $c-lighten;
			border: 1px dotted $c-darken;
			border-radius: $borderRadius;
			@include t_smallish;
		}

		& input.error {
			background: $c-rustDust;
			border: 1px solid $c-rust;
		}

		& .hs-error-msgs {
			display: none !important;
			& li { display: none; }
		}

	}

	& ul.hs-error-msgs {

		@include nakedList;
		@include altTitleFont;
		@include fontsize($smallFontSize);

		margin-right: $navMargin;
		padding: $navMargin/2 0;

		li {
			padding: $navMargin $defaultMargin;
			color: $c-rust;
			background: $c-rustDust;
			font-weight: $fw-bold;
		}
	}

	.hs_submit input[type=submit] {
		display: inline-block;
		margin: 0;
		padding: $defaultMargin*.62 $defaultMargin*.7;
		@include buttonColors($c-plum, $c-white);
		@include altTitleFont;
		@include t_smallish;
		font-weight: $fw-bold;
		text-transform: uppercase;
		line-height: 1;
		cursor: pointer;

	}

	& .submitted-message {
		height: 100%;
		padding: $navMargin;
		color: $c-darkGreen;
		background: $c-greenDust;
		font-weight: $fw-bold;
		@include fontsize($smallFontSize);
	}

}*/
/*
.library_controlsList {
  list-style:none;

  li {
    margin-left: 0;

    .library_facet {
      span.title {
        text-transform: capitalize;
      }
    }
  }

}*/
.searchbar {
  position: relative; }
  .searchbar > .searchbar_input {
    display: block;
    width: 100%;
    padding: 0.875rem 0.875rem 0.875rem 3.5rem;
    border: 3px solid #f4f4f4;
    font-size: 20px;
    font-size: 1.25rem;
    border-radius: 1px; }
    @media only screen and (min-width: 0px) and (max-width: 600px) {
      .searchbar > .searchbar_input {
        padding-left: 0.875rem;
        font-size: 20px;
        font-size: 1.25rem; } }
  .searchbar > .searchbar_icon {
    pointer-events: none;
    position: absolute;
    top: 23px;
    left: 23px;
    height: 100%;
    width: 1.75rem;
    color: #a9d5dc; }
    .searchbar > .searchbar_icon > svg {
      height: 100%;
      width: 1.75rem; }
    @media only screen and (min-width: 0px) and (max-width: 600px) {
      .searchbar > .searchbar_icon {
        display: none; } }

.library_results mark {
  background-color: transparent;
  background-image: linear-gradient(-180deg, transparent, rgba(127, 238, 59, 0.7) 35%, transparent); }

.library_results .articleImageFrame {
  padding-bottom: 0;
  margin-bottom: 1em; }

.library_results h4 {
  font-size: 22px;
  font-size: 1.375rem;
  padding: 0 !important;
  margin: 0 !important; }

.library_results .articlePreviewCard_meta {
  font-size: 16px;
  font-size: 1rem; }

.library_searchbarContainer {
  margin-bottom: 3rem; }

.library_controls {
  background-color: #f4f4f4;
  margin-right: 3rem;
  padding: 0.875rem 3rem 3rem;
  border-radius: 1px; }

.library_controlsHeading {
  margin: 0 0 0.875rem;
  padding: 0 0 4px;
  font-family: museo-slab;
  font-weight: 500;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 900;
  text-transform: uppercase;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.2); }

.library_controlsList {
  margin: 0 0 3rem;
  list-style: none;
  line-height: 1.2;
  font-family: museo-slab;
  font-weight: 500;
  font-size: 14px;
  font-size: 0.875rem; }
  .library_controlsList li {
    margin: 0.6125rem 0; }
    .library_controlsList li.selected > .library_facet {
      font-weight: 900;
      color: #f9991a; }
  .library_controlsList li ul {
    margin: 0 0 0 0.875rem; }
  .library_controlsList input[type=checkbox] {
    display: none; }
  .library_controlsList + button {
    display: block;
    margin-top: -1.5rem;
    margin-bottom: 3rem; }

.library_facet {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto; }
  .library_facet > .count {
    display: inline-block;
    justify-self: end;
    margin-left: auto;
    padding-left: 0.3125rem; }
  .library_facet span.title {
    text-transform: capitalize; }
  .library_facet:hover > .title {
    text-decoration: underline; }

.library_searchResultSnippet {
  margin: 0.875rem 0;
  padding: 0 0 0.875rem;
  border-bottom: 3px solid #eee;
  font-size: 16px;
  font-size: 1rem;
  font-style: italic; }
  .library_searchResultSnippet.__default {
    border-color: rgba(154, 154, 157, 0.8); }
  .library_searchResultSnippet.__blog {
    border-color: rgba(235, 66, 52, 0.8); }
  .library_searchResultSnippet.__webinar {
    border-color: rgba(126, 213, 231, 0.8); }
  .library_searchResultSnippet.__resource {
    border-color: rgba(127, 238, 59, 0.8); }
  .library_searchResultSnippet.__podcast {
    border-color: rgba(251, 184, 86, 0.8); }

.library_pagebar {
  background-color: #f7f7f7;
  padding: 1rem 2rem;
  border-radius: 1px;
  font-family: museo-slab;
  font-weight: 500;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1; }

.library_pagebar_pageLink {
  padding: .5rem;
  text-decoration: none; }
  @media only screen and (max-width: 601px) {
    .library_pagebar_pageLink {
      padding: 1rem; } }
  .library_pagebar_pageLink.selected {
    text-decoration: underline;
    font-weight: 900; }
  button.library_pagebar_pageLink:hover {
    text-decoration: underline; }
  span.library_pagebar_pageLink {
    cursor: default; }

.library_alert {
  background-color: #fbb856;
  padding: 3rem;
  font-size: 14px;
  font-size: 0.875rem;
  border-radius: 1px; }

.library_info {
  background-color: #f4f4f4;
  padding: 1rem;
  font-size: 14px;
  font-size: 0.875rem;
  border-radius: 1px; }
  .library_info button {
    display: inline-block;
    padding: .625rem;
    background-color: rgba(0, 0, 0, 0.05);
    color: currentColor;
    font-weight: 900;
    line-height: 1;
    text-decoration: none;
    border-radius: 1px;
    letter-spacing: 0;
    text-transform: capitalize; }

.library_error {
  background-color: #ef4c3b;
  padding: 3rem;
  font-size: 14px;
  font-size: 0.875rem;
  border-radius: 1px;
  color: white; }
