.tooltip {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    left:50%;
    opacity: 0;
  }

  &::before {
    content:"";
    border-width: 0 8px 10px 8px;
    border-style: solid;
    border-color: transparent transparent rgba(0,0,0,0.5) transparent;
    margin-left: -19px;
    bottom:-14px;
  }

  &::after {
    content: attr(data-tooltip);
    background-color: rgba(0,0,0,0.5);
    bottom:-15px;
    width: 140px;
    margin-left:-80px;
    font-size:12px;
    padding:10px;
    border-radius:20px;
    color:white;
    transform: translateY(100%);
  }

  &:hover {
    opacity: 1 !important;

    &::before,
    &::after {
      opacity: 1 !important;
    }
  }

}